
import { Injectable } from '@angular/core';
import {AngularFireStorage } from '@angular/fire/compat/storage';





export class FileUpload {
  key: string | undefined;
  name: string | undefined;
  url: string | undefined;
  file: File;

  constructor(file: File) {
    this.file = file;
  }
}




@Injectable({
  providedIn: 'root'
})
export class FirestorageService {

  constructor(
    private storage: AngularFireStorage) {}


  async uploadFileSync(file:any, firestoragePath:string){
    const task = this.storage.upload(firestoragePath, file);
    const ref = this.storage.ref(firestoragePath);
    let uploadPercentage = task.percentageChanges();
    // upload image, save url
    await task;
    let imageUrl = await ref.getDownloadURL().toPromise();
    return imageUrl
  }


  uploadFile(file:any, firestoragePath:string){
    this.storage.upload(firestoragePath, file);
  }


  deleteFileUsingFirestoragePath(firestoragePath:string){
    return this.storage.ref(firestoragePath).delete()
  }

  deleteFileUsingDownloadUrl(downloadUrl:string){
    return this.storage.storage.refFromURL(downloadUrl).delete()
  }


  // TODO: TBD ----- Improve this method to rise errors and to be awaitable ------------------------
  async deleteFolderUsingFirestoragePath(firestoragePath: string): Promise<void> {
    // Find all the prefixes (folders) and items (files) and process them in a recursive way
    const list = await this.storage.ref(firestoragePath).listAll().toPromise();
    await this.deleteFilesAndFoldersInsideFolderList(list);
  }
  async deleteFilesAndFoldersInsideFolderList(list: any): Promise<void> {
      // Process folders, it may be recursive
      if (list == null || list === undefined) return;
      // All the prefixes under listRef (subfolders)
      const folderPromises = list.prefixes.map(async (folderRef) => {
          const folderList = await folderRef.listAll();
          await this.deleteFilesAndFoldersInsideFolderList(folderList);
      });
      // Process files (delte them one by one)
      const filePromises = list.items.map((fileRef) => {
          return fileRef.delete();
      });
      // Wait for all promises to finish
      await Promise.all([...folderPromises, ...filePromises]);
  }



  // TODO: TBD ----- Improve this method to rise errors and to be awaitable ------------------------
  async deleteFilesThatAreNotInList(firestoragePath: string, fileNameList: string[]): Promise<void>{
    // Find all the prefixes (folders) and items (files) and process them in a recursive way
    const list = await this.storage.ref(firestoragePath).listAll().toPromise();
    // Process folders, it may be recursive
    if (list == null || list === undefined) return;
    // Process files (delte them one by one)
    const filePromises = list.items.map((fileRef) => {
      if(!fileNameList.includes(fileRef.name)){
        return fileRef.delete();
      } else {
        return true
      }
    });
    // Wait for all promises to finish
    await Promise.all([...filePromises]);
  }


  


}