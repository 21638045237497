import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

// Needed for firebase timestamp
import firebase from 'firebase/compat/app';




@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private firestore: AngularFirestore) { }

  // Add document to DB
  public addDocument(collection: string, document: string, data: any, merge: boolean = false){
    return this.firestore.collection(collection).doc(document).set(data, {merge:merge});
  }

  // Get document from DB
  public getDocument(collection: string, document: string){
    return this.firestore.collection(collection).doc(document).ref.get();
  }

  // Delete document
  public deleteDocument(collection: string, document: string){
    return this.firestore.collection(collection).doc(document).ref.delete()
  }



  // Get collection from DB
  //  Possible filters
  //    {key:'where', value:['parameter', 'condition', 'comparison value']}
  //    {key:'orderBy', value:'parameter'}
  //    {key:'limit', value:number}
  //    {key:'startAfter', value:lasDocument}
  public getCollection(collection: string, conditionsList:any=[]){
    // Get the collection ref
    let collectionRef = this.firestore.collection(collection).ref
    // applay the filters
    let filteredCollectionRef:any = collectionRef
    for(let condictionDict of conditionsList){
      let value = condictionDict.value
      if(condictionDict.key == 'where'){
        filteredCollectionRef = filteredCollectionRef.where(value[0], value[1], value[2])
      }else if(condictionDict.key == 'orderBy'){
        filteredCollectionRef = filteredCollectionRef.orderBy(value[0], value[1])
      }else if(condictionDict.key == 'limit'){
        filteredCollectionRef = filteredCollectionRef.limit(value)
      }else if(condictionDict.key == 'startAfter'){
        filteredCollectionRef = filteredCollectionRef.startAfter({'reportId': value})
      }
    }
    // Return the result
    return filteredCollectionRef.get()
  }
  

  // Delete all documents from collection
  public async deleteAllDocumentsFromCollection(collection: string) {
    // Get documents from collection
    const collectionSnapshot = await this.getCollection(collection);
    // Iterate and delete them
    const deletePromises = collectionSnapshot.docs.map(doc => doc.ref.delete());
    return await Promise.all(deletePromises);
  }

  

  // Get firebase hash
  public getFirebaseHash(){
    return this.firestore.collection('server').doc().ref.id
  }

  // Get firebase timestamp
  public getFirebaseTimestamp(){
    return firebase.firestore.FieldValue.serverTimestamp()
  }


  // Get collection from DB
  async getDocumentListFromCollection(collection: string, conditionsList:any=[]){
    // Get the collection snapshot
    let collectionSnapshot = await this.getCollection(collection, conditionsList)
    // Store the documents to a single list
    let documentList:any = []    
    for(let documentSnapshot of collectionSnapshot.docs){
      let docId = documentSnapshot.id
      let docData = documentSnapshot.data()
      documentList.push({id:docId,data:docData})
    }
    // Return the result dict
    return documentList    
  }




  



}