import { Component, OnInit } from '@angular/core';

import {FormControl, FormGroupDirective, NgForm, Validators, FormGroup, AbstractControl } from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

import { GlobalConstant } from 'src/app/constants/global-constant';
import { LanguageService } from 'src/app/services/language/language.service'


/* Error when invalid control is dirty, touched, or submitted (input fields) */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    //const isSubmitted = form && form.submitted;
    //return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    return !!(control && control.invalid && (control.dirty || control.touched ));
  }
}



@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.css']
})
export class TextFieldComponent implements OnInit {



  // Input parameters. Pass through the reference when creating the view
  public inputParameters:any = {}
  public inputData:any = {}

  
  // Individual form controls and error matcher
  formControlGroup:any;
  textFormControl = new FormControl('', [
    this.specialCharactersValidators.bind(this)
  ]); 
  matcher = new MyErrorStateMatcher();
  
  




  constructor(public languageService: LanguageService) { }


  ngOnInit(): void {
    
    // Create the form control group
    this.formControlGroup = new FormGroup({
      value: this.textFormControl
    })
    // N Characters validator
    this.textFormControl.addValidators(Validators.maxLength(GlobalConstant.formField.textField.maxLength))
    // Set the current value   
    if(this.inputData){
      if(this.inputData.value){
        this.textFormControl.setValue(this.inputData.value)
      }
    }
  }











  // ----- Custom validators ------ //

  specialCharactersValidators(control: AbstractControl) {
    let currentString = control.value
    if(currentString){
      if(currentString.length > 0){
        if(!GlobalConstant.formField.textField.addmittedCharacters.test(currentString)){
          return { specialCharactersValidationError: true };
        }
      }
    }
    return null;
  }
  // spacesInStringValidators(control: AbstractControl) {
  //   let currentString = control.value
  //   if(currentString){
  //     if(currentString.length > 0){
  //       if(currentString.indexOf(' ') >= 0){
  //         return { spacesInStringValidationError: true };
  //       }
  //     }
  //   }
  //   return null;
  // }





  // ----- Functions to return the corresponding information to parent class ------ //

  getResultDict(){
    let resultDict = {
      key: this.inputParameters.key,
      type: this.inputParameters.type,
      value: this.formControlGroup.value.value
    }
    return resultDict
  }






}
