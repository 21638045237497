<div class="input_box">

    <mat-label class="tag-label-div" [ngClass]="{ 'disabled-label': fieldDisabled }">
        {{inputParameters.name}}
    </mat-label>

    <!-- Existing files -->
    <mat-list class="global-list-div" *ngIf="(dicomData != null) && (dicomService.getNumberOfPatients() == 0)">
        <div class="single-item-div-internal">
            <div class="single-item-div-left" [ngClass]="{ 'disabled-label': fieldDisabled }">
                {{languageService.Strings.dicomFieldStrings.information_UploadedTotal}} - {{dicomData.description}}
            </div>
            <div class="single-item-div-right">
                <button mat-icon-button 
                    color="primary" 
                    (click)="downloadFiles()"
                    matTooltip={{languageService.Strings.landingPageStrings.button_download}}>
                    <mat-icon>cloud_download</mat-icon>
                </button>
                <button mat-icon-button 
                    color="primary" 
                    (click)="deleteBackendFiles()"
                    [disabled]="fieldDisabled"
                    matTooltip={{languageService.Strings.landingPageStrings.button_delete}}>
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </mat-list>


    <!-- Selected files -->
    <mat-list class=global-list-div *ngIf="dicomService.getNumberOfPatients() > 0">
        <div class="single-item-div-internal">
            <div class="single-item-div-left" [ngClass]="{ 'disabled-label': fieldDisabled }">
                {{languageService.Strings.dicomFieldStrings.information_SelectedTotal}} - {{getInformationStr()}}
            </div>
            <div class="single-item-div-right">
                <!--
                <button mat-icon-button 
                    color="primary" 
                    (click)="downloadFiles()"
                    matTooltip={{languageService.Strings.landingPageStrings.button_download}}>
                    <mat-icon>cloud_download</mat-icon>
                </button>
                -->
                <button mat-icon-button 
                    color="primary" 
                    (click)="deleteFrontendFiles()"
                    [disabled]="fieldDisabled"
                    matTooltip={{languageService.Strings.landingPageStrings.button_delete}}>
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </mat-list>
        

    <app-dir-selector (selectedFilesEvent)="onDirSelected($event)"
        [fileUploaderButtonText]="languageService.Strings.dirSelector.button_selectDirectory"
        [disabled]="fieldDisabled">
    </app-dir-selector>

</div>




<!-- Waiting spinner -->
<div *ngIf="(displayWaitingSpiner)">
    <app-waiting-spinner [waitingMessage]="waitingMessage"></app-waiting-spinner>
</div>


<!-- Progress bar -->
<div *ngIf="(displayProgressIndicator)" >
    <app-progress-indicator
        [waitingMessage]="waitingMessage"
        [progressIndicator]="progressIndicator"
    ></app-progress-indicator>
</div>