



export const StringsES = {
    // Specific form 
    form : {
        button_accept: "Aceptar",
        button_cancel: "Cancelar",
        default_tab_name: "Nueva pestaña",
        default_panel_name: "Nuevo panel",
    },
    // Registration and authentication processes
    authentication: {
        register_success: "Se le ha enviado un enlace a su correo para su validación.",       
        error_emailNotVerified: "El email aun no ha sido verificado.",
        resetPasswordemail_sent: "Se ha enviado un correo a ",
        resetPasswordemail_emailNotFound: "El usuario especificado no está registrado",
        resetPasswordemail_unknownError: "Error desconocido, inténtelo de nuevo más tarde o contacte con soporte si no se soluciona",
    },
    // Searching box
    searchingBox: {
        input_box_label_patientId: 'Código',
        button_newPatient: 'Añadir',
        button_findPatient: 'Buscar',
        button_accessPatient: 'Acceder',
        button_downloadExcel: 'Descargar Excel',
        error_spaces: 'El campo no puede contener espacios',
        error_specialCharacters: 'Signos no permitidos'
    },
    // File selector
    fileSelector: {
        button_selectFiles: 'Seleccionar',
    },
    // Dir selector
    dirSelector: {
        button_selectDirectory: 'Seleccionar'
    },
    // ---------- Error messages ---------- //
    errorMessages: {
        error_maxValue: "¡Error! Valor demasiado grande",
        error_minValue: "¡Error! Valor demasiado pequeño",
        error_step: "¡Error! Resolución demasiado elevada",
        error_required: '¡Error! Campo obligatorio',
        error_maxLength: '¡Error! Demasiados caracteres',
        error_specialCharacters: '¡Error! Caracteres no permitidos',
        error_spacesInString: '¡Error! No puede contener espacios',
        error_email: '¡Error! El campo debe ser un email válido',
        error_downloadExcel: "No se ha podido descargar el archivo Excel. Contacte con soporte.",
        error_excelNotAvailable: "El archivo Excel no está disponible. Contacte con soporte.",
    },
    // ---------- Popups  ---------- //
    informationPopup: {
        button_accept: "Aceptar",
    },
    // ---------- Pages ---------- //
    loginPageStrings: {
        login_error_unauthorized: "Usuario no autorizado",
        login_error_unknown: "Login error",
        button_login_google: "Login con Google",
        button_login_microsoft: "Login con Microsoft",
        button_login_yahoo: "Login con Yahoo"
    },
    landingPageStrings: {
        button_back: 'Volver',
        waitingMessage_NewPatient: 'Añadiendo paciente',
        informationPopup_userAlreadyExists_title: 'Acción fallida',
        informationPopup_userAlreadyExists_message: 'El paciente ya existe',
        informationPopup_error_title: 'Error',
        waitingMessage_FindPatient: 'Buscando',
        informationPopup_patientDoesNotExist_title: 'Acción fallida',
        informationPopup_patientDoesNotExist_message: 'El paciente no existe',
        waitingMessage_UpdatePatient: 'Actualizando datos',
        button_accept: "Aceptar",
        button_edit: "Editar",
        button_cancel: "Cancelar",
        button_download: 'Descargar',
        button_delete: 'Eliminar',
        button_logout: 'Cerrar sesión',
        lastUpdateExcel: "Última actualización del Excel",
        excelStudy: "Estudio del Excel",
        deletePatientConfirmationTitle: "Eliminar paciente",
        deletePatientConfirmationText: "La información eliminada no se puede restaurar",
    },
    // ---------- Specific form items ---------- //
    dicomFieldStrings: {
        information_Patient: 'Paciente',
        information_Study: 'Estudio',
        information_Series: 'Serie',
        information_UploadedSeries: 'Serie almacenada',
        information_UploadedStudy: 'Estudio almacenado',
        waitingMessage_ParsingDicom: 'Cargando DICOM',
        waitingMessage_DownloadingDicom: 'Descargando DICOM',
        dicomZipDirName: 'DicomSerie',
        information_UploadedTotal: 'Almacenado',
        information_SelectedTotal: 'Seleccionado',
        information_TotalPatients: 'Pacientes: ',
        information_TotalSeries: 'Series: ',
        information_TotalImages: 'Imágenes: '
    }
}



