import { Injectable, HostListener } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { saveAs } from 'file-saver';

import JSZip from 'jszip';;  // npm install jszip



// Dialog popups
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InformationPopupComponent } from 'src/app/components/popups/information-popup/information-popup.component';



@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  // Current window inner width
  windowInnerWidth:any


  constructor(private _snackBar: MatSnackBar, public dialog: MatDialog) { }


  // ---------- Key manaement ---------- //
  generateFieldKey(fieldName:string){
    return fieldName.trim().toLowerCase().replace(' ', '_').replace('__', '_')
  }

  // ---------- Dictionary deep copy ---------- //
  deepCopyDict(object:any){
    return JSON.parse(JSON.stringify(object))
    // return structuredClone(object)   -> Right way, still beta. Will be out soon
  }

  // Keep window inner width
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.windowInnerWidth = window.innerWidth;
  }


  // ---------- Snack bar ---------- //
  displaySnackBar(message:string){
    this._snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition:'center',
      verticalPosition:'bottom'
    });
  }


  // ---------- Open external url --------------- //
  openUrlInNewWindow(url:string, data:any){
    /*
    const newWindow:any = window.open(url, "_blank");
    newWindow.postMessage('test data', '*')*/
    let mapForm = document.createElement("form");
    mapForm.target = "_blank";
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = url;
    Object.keys(data).forEach(function(param){
      let mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", data[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    mapForm.submit();
  }



  downloadFile(url: string, filename: string) {
    fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.blob();
        })
        .then(blob => {
            saveAs(blob, filename);
        })
        .catch(error => console.error(error));
  }

  async downloadFileListAndZip(list: { name: string, downloadUrl: string }[], zipDirName:string) {
    const zip = new JSZip();
    // Promises to wait for all the files to be downloaded
    const downloadPromises = list.map(item =>
      fetch(item.downloadUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.blob();
        })
        .then(blob => {
          zip.file(item.name, blob);
        })
    );
    // Wait for all files to be downloaded 
    try {
      await Promise.all(downloadPromises);
      // ZIP directory generation
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      saveAs(zipBlob, zipDirName+'.zip');
    } catch (error) {
      console.error('Error:', error);
    }
  }
  


  downloadLocalFile(file: File) {
    // Crear una URL para el archivo
    const url = URL.createObjectURL(file);
    // Crear un elemento <a> temporal
    const a = document.createElement('a');
    a.href = url;
    a.download = file.name; // Nombre del archivo para descargar
    // Añadir el elemento <a> al documento y hacer clic en él
    document.body.appendChild(a);
    a.click();
    // Limpiar y remover el elemento <a> del documento
    document.body.removeChild(a);
    URL.revokeObjectURL(url); // Liberar la URL creada
  }


  async downloadLocalFileListAndZip(files: File[], zipDirName:string) {
    const zip = new JSZip();
    // Agregar cada archivo al archivo zip
    files.forEach(file => {
      zip.file(file.name, file);
    });
    // Generar el archivo zip y descargarlo
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, zipDirName+'.zip');
  }




  displayInformationPopup(informationTitle:string, informationMessage:string){
    this.dialog.open(
      InformationPopupComponent, 
      {
        disableClose: true,
        data: {informationTitle:informationTitle, informationMessage:informationMessage},
        minWidth: '300px',
      } 
    )
  }

  
}
