import { Injectable } from '@angular/core';

import * as dicomParser from 'dicom-parser'; // npm install dicom-parser

import * as dcmjs from 'dcmjs';  // npm i dcmjs

// Constant global
import { GlobalConstant } from 'src/app/constants/global-constant';

// Services 
import { FirestoreService } from '../firestore/firestore.service';


@Injectable({
  providedIn: 'root'
})
export class DicomService {

  // Variables to be managed by the UI
  dicomdir_structure:any = null   
  totalNumberOfSeries:number = null
  totalNumberOfImages:number = null 
  instanceUIDsDict:any = null

  
  constructor(public firestoreService:FirestoreService) {}


  initializeAllVariables(){
    // Variables to be managed by the UI
    this.dicomdir_structure = {'PAT': {}};
    this.totalNumberOfSeries = 0
    this.totalNumberOfImages = 0
    this.instanceUIDsDict = {'study':{}, 'series':{}}
  }


  async loadDicomdirStructure(fileArray:any){
    // Clear all previous data
    this.initializeAllVariables()
    // Load all the files under the specified directory
    let nFiles = fileArray.length
    for(let fileIndex = 0; fileIndex < nFiles; fileIndex++){
      // Read the file content into a dicom dataset. Async but wait for it to finish
      if(GlobalConstant.dicom.filenamesToAvoid.includes(fileArray[fileIndex].name)) continue
      let dataSet = await this.readFileAsync(fileArray[fileIndex])  
      if(dataSet === undefined) continue
      // Get patient information
      let patient_id = dataSet.string('x00100020') // Patient id
      if (patient_id === undefined) continue
      //let boolVar = patient_id in this.dicomdir_structure['PAT']
      if (!(patient_id in this.dicomdir_structure['PAT'])){
        this.addNewPatient(dataSet, patient_id)
      }
      // Get study information
      let study_uid = dataSet.string('x0020000d') // Study uid
      if (study_uid === undefined) continue
      if (!(study_uid in this.dicomdir_structure['PAT'][patient_id]['STU'])){
        this.addNewStudy(dataSet, patient_id, study_uid)
      }
      // Get the serie information
      let serie_uid = dataSet.string('x0020000e') // Serie uid
      if (serie_uid === undefined) continue
      if (!(serie_uid in this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['SER'])){
        this.addNewSeries(dataSet, patient_id, study_uid, serie_uid)
      }
      // Get the image data
      this.addNewImage(dataSet, patient_id, study_uid, serie_uid, fileArray[fileIndex])
    }
    // Format dicom structure // TODO: TBD -- Return true / false (not needed??)
    this.formatDicomdirStructure()
  }


  // Read file async but wait for the result
  readFileAsync(file): Promise<dicomParser.DataSet | undefined>  {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        try{
          let arrayBuffer = reader.result as ArrayBuffer;
          let byteArray = new Uint8Array(arrayBuffer);
          let dataSet:dicomParser.DataSet = dicomParser.parseDicom(byteArray);
          resolve(dataSet);
        }catch{
          resolve(undefined)
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    })
  }


  addNewPatient(dataSet, patient_id){
    // Create the dictionary
    this.dicomdir_structure['PAT'][patient_id] = {'data': {}, 'STU': {}}
    // Add the custom data
    let informationStr = patient_id
    this.dicomdir_structure['PAT'][patient_id]['data']['informationStr'] = informationStr
  }

  addNewStudy(dataSet, patient_id, study_uid){
    // Create the dictionary
    this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid] = {'data': {}, 'SER': {}}
    // Add the custom data
    let study_description = dataSet.string('x00081030') // Study description
    let study_date = dataSet.string('x00080020') // Stady date
    // Generate the information string
    let informationStr = 'No study information'
    if (study_description != undefined && study_description.replaceAll(' ', '').length > 0 && study_date != undefined && study_date.replaceAll(' ', '').length > 0){
      informationStr = study_description + ' - ' + study_date
    }else if(study_description != undefined && study_description.replaceAll(' ', '').length > 0){
      informationStr = study_description
    }else if(study_date != undefined && study_date.replaceAll(' ', '').length > 0){
      informationStr = study_date
    }
    this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['data']['informationStr'] = informationStr
    // Generate the uid to be used when uploading the file to storage
    this.generateDicomUID('study', study_uid)
  }

  addNewSeries(dataSet, patient_id, study_uid, serie_uid){
    // Verify that the target serie corresponds to a serie modality usefull for the software
    let serie_modality = dataSet.string('x00080060') // Series modality
    if (serie_modality.toUpperCase().replaceAll(' ', '') != 'CT'){
      return
    }  
    // Create the serie object
    //this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['SER'][serie_uid] = {'data': {},'IMG': {'file': [],'zpos': []}}
    this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['SER'][serie_uid] = {'data': {},'IMG': {'file': []}}
    // Add the custom data
    let serie_number = dataSet.string('x00200011') // Series number 
    if(serie_number){
      this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['SER'][serie_uid]['data']['informationStr'] = serie_number + ' - Images: '
    }else{
      this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['SER'][serie_uid]['data']['informationStr'] = 'Images: '
    }
    // Generate the uid to be used when uploading the file to storage
    this.generateDicomUID('series', serie_uid)
  }

  addNewImage(dataSet, patient_id, study_uid, serie_uid, file){
    // Verify that the target image corresponds to a image type usefull for the software
    let image_type = dataSet.string('x00080008') 
    if(image_type != undefined && !image_type.split('\\').includes('AXIAL')) return
    // Get all the required parameters needed for including the target image
    //   Image position (z)
    let imagePositionZ = dataSet.floatString('x00200032', 2)
    if (imagePositionZ === undefined) return
    //   Image Rescale intercept and slope
    let image_rescaleIntercept = dataSet.floatString('x00281052')
    let image_rescaleSlope = dataSet.floatString('x00281053')
    if (image_rescaleIntercept === undefined || image_rescaleSlope === undefined) return
    //   Pixel spacing
    let image_pixelSpacing = [
      dataSet.floatString('x00280030', 0),
      dataSet.floatString('x00280030', 1),
    ];
    if (image_pixelSpacing[0] === undefined || image_pixelSpacing[1] === undefined) return
    // Store pixel spacing
    if (!('pixelSpacing' in this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['SER'][serie_uid])){
      this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['SER'][serie_uid]['pixelSpacing'] = image_pixelSpacing[0]
    }
    // Add the image to the corresponding lists
    this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['SER'][serie_uid]['IMG']['file'].push(file)
  }


  formatDicomdirStructure(){
    // Sort / Delete data
    for(let patient_id of Object.keys(this.dicomdir_structure['PAT'])){
      for(let study_uid of Object.keys(this.dicomdir_structure['PAT'][patient_id]['STU'])){
        for(let serie_uid of Object.keys(this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['SER'])){
          let nImages = this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['SER'][serie_uid]['IMG']['file'].length
          if(nImages == 0){
            // Delete empty series
            delete this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['SER'][serie_uid]
          }else{
            // Add the information string
            this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['SER'][serie_uid]['data']['informationStr'] += nImages.toString()
            // Increase the total number of images and series counter
            this.totalNumberOfImages += nImages
            this.totalNumberOfSeries += 1
          }
        }
        // Delete empty studies
        let nSeries = Object.keys(this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['SER']).length
        if(nSeries == 0){
          delete this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]
        }
      }
      // Delete empty patients
      let nStudies = Object.keys(this.dicomdir_structure['PAT'][patient_id]['STU']).length
      if(nStudies == 0){
        delete this.dicomdir_structure['PAT'][patient_id]
      }
    }
  }


  getTotalNumberOfImages(){
    return this.totalNumberOfImages
  }

  getTotalNumberOfSeries(){
    return this.totalNumberOfSeries
  }

  getAllFiles(){
    let fileList = []
    for(let patient_id of Object.keys(this.dicomdir_structure['PAT'])){
      for(let study_uid of Object.keys(this.dicomdir_structure['PAT'][patient_id]['STU'])){
        for(let serie_uid of Object.keys(this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['SER'])){
          fileList = fileList.concat(this.dicomdir_structure['PAT'][patient_id]['STU'][study_uid]['SER'][serie_uid]['IMG']['file']);
        }
      }
    }
    return fileList
  }




  // ----------------------- Methods to interact with the main window view ----------------------- //

  getNumberOfPatients(){
    return Object.keys(this.dicomdir_structure['PAT']).length
  }

  getPatientIdFromIndex(patientIndex:number){
    let patientList = Object.keys(this.dicomdir_structure['PAT'])
    patientList.sort((a, b) => a.localeCompare(b));
    return patientList[patientIndex] 
  }

  getPatientInformationFromIndex(patientIndex:number){
    let patientList = Object.keys(this.dicomdir_structure['PAT'])
    patientList.sort((a, b) => a.localeCompare(b));
    let patient_id = patientList[patientIndex]
    return this.dicomdir_structure['PAT'][patient_id]['data']['informationStr']
  }

  getPatientInformationList(){
    let nPatients = this.getNumberOfPatients()
    let patientInformationList:any = []
    for(let index=0; index<nPatients; index++){
      patientInformationList.push(this.getPatientInformationFromIndex(index))
    }
    return patientInformationList
  }

  


  getNumberOfStudies(patientIndex:number){
    let patient_id = this.getPatientIdFromIndex(patientIndex)
    return Object.keys(this.dicomdir_structure['PAT'][patient_id]['STU']).length
  }

  getStudyIdFromIndex(patientIndex:number, studyIndex:number){
    let patient_id = this.getPatientIdFromIndex(patientIndex)
    let studyList = Object.keys(this.dicomdir_structure['PAT'][patient_id]['STU'])
    studyList.sort((a, b) => a.localeCompare(b));
    let study_id = studyList[studyIndex]
    return [patient_id, study_id]
  }

  getStudyInformationFromIndex(patientIndex:number, studyIndex:number){
    let id_list = this.getStudyIdFromIndex(patientIndex, studyIndex)
    return this.dicomdir_structure['PAT'][id_list[0]]['STU'][id_list[1]]['data']['informationStr']
  } 

  getStudyInformationList(patientIndex:number){
    let nStudies = this.getNumberOfStudies(patientIndex)
    let studyInformationList:any = []
    for(let studyIndex=0; studyIndex<nStudies; studyIndex++){
      studyInformationList.push(this.getStudyInformationFromIndex(patientIndex, studyIndex))
    }
    return studyInformationList
  }


  


  getNumberOfSeries(patientIndex:number, studyIndex:number){
    let id_list = this.getStudyIdFromIndex(patientIndex, studyIndex)
    return Object.keys(this.dicomdir_structure['PAT'][id_list[0]]['STU'][id_list[1]]['SER']).length
  }

  getSeriesIdFromIndex(patientIndex:number, studyIndex:number, seriesIndex:number){
    let id_list = this.getStudyIdFromIndex(patientIndex, studyIndex)
    let seriesList = Object.keys(this.dicomdir_structure['PAT'][id_list[0]]['STU'][id_list[1]]['SER'])
    seriesList.sort((a, b) => a.localeCompare(b));
    let series_id = seriesList[seriesIndex]
    id_list.push(series_id)
    return id_list
  }

  getSeriesInformationFromIndex(patientIndex:number, studyIndex:number, seriesIndex:number){
    let id_list = this.getSeriesIdFromIndex(patientIndex, studyIndex, seriesIndex)
    return this.dicomdir_structure['PAT'][id_list[0]]['STU'][id_list[1]]['SER'][id_list[2]]['data']['informationStr']
  } 

  getSeriesInformationList(patientIndex:number, studyIndex:number){
    let nSeries = this.getNumberOfSeries(patientIndex, studyIndex)
    let seriesInformationList:any = []
    for(let seriesIndex=0; seriesIndex<nSeries; seriesIndex++){
      seriesInformationList.push(this.getSeriesInformationFromIndex(patientIndex, studyIndex, seriesIndex))
    }
    return seriesInformationList
  }



  
  getSeriesFiles(patientIndex:number, studyIndex:number, seriesIndex:number){
    let id_list = this.getSeriesIdFromIndex(patientIndex, studyIndex, seriesIndex)
    let fileList = this.dicomdir_structure['PAT'][id_list[0]]['STU'][id_list[1]]['SER'][id_list[2]]['IMG']['file']
    return fileList
  }
  




  async anonymizeDicomFile(file, patientIdToBeSet, studyIdToBeSet){
    // Anonymize dicom data and store it to another blob
    let arrayBuffer = await this.readFileArrayAsync(file)
    let dicomData = dcmjs.data.DicomMessage.readFile(arrayBuffer);
    let dataSet = dcmjs.data.DicomMetaDictionary.naturalizeDataset(dicomData.dict);
    for(let key of Object.keys(dataSet)){
      // If the variable is the patientId or the studyId, replace it with the specified value
      if(key == 'PatientID') dataSet[key] = patientIdToBeSet
      else if(key == 'StudyID') dataSet[key] = studyIdToBeSet
      else if(key == 'StudyInstanceUID') dataSet[key] = this.getUniqueIdentifier('study', dataSet[key])
      else if(key == 'SeriesInstanceUID') dataSet[key] = this.getUniqueIdentifier('series', dataSet[key])
      else if(key == 'SOPInstanceUID') dataSet[key] = this.getUniqueIdentifier('image', dataSet[key])
      else if(!GlobalConstant.dicom.dicomKeysToKept.includes(key)) delete dataSet[key]
    }
    // Store the new file
    dicomData.dict = dcmjs.data.DicomMetaDictionary.denaturalizeDataset(dataSet);
    let newArrayBuffer = dicomData.write();
    // Create a Blob from the byte array
    let blob = new Blob([newArrayBuffer], {type: "application/dicom"});
    // Clear variables for collector
    arrayBuffer = null;
    dicomData = null;
    dataSet = null;
    newArrayBuffer = null;
    // return the blob
    return blob
  }
  readFileArrayAsync(file): Promise<any>  {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        try{
          let arrayBuffer = reader.result as ArrayBuffer;
          resolve(arrayBuffer);
        }catch{
          resolve(undefined)
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    })
  }


  getUniqueIdentifier(level, dicomUid){
    if(this.instanceUIDsDict === null) this.instanceUIDsDict = {'study':{}, 'series':{}}
    if(level == 'study' || level == 'series'){
      if(dicomUid in this.instanceUIDsDict[level]) return this.instanceUIDsDict[level][dicomUid]
    }
    return this.generateDicomUID(level, dicomUid)
  }

  generateDicomUID(level, dicomUid) {
    // Get a firebase hash
    let firebaseHash = this.firestoreService.getFirebaseHash()
    // Supongamos que '2.25' es el prefijo elegido
    const prefix = '2.25';
    // Convertir hash alfanumérico a una representación numérica grande
    const numericPart = parseInt(firebaseHash.split('').map(char => char.charCodeAt(0).toString()).join('')).toString().slice(0, 38);
    // Generate the unique id
    const uid = `${prefix}.${numericPart}`
    // Update the reference dictionariy 
    if (level == 'study' || level == 'series') this.instanceUIDsDict[level][dicomUid] = uid
    // Return the uid
    return uid;
  }


}
