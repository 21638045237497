<div class="input_box">
    
        <mat-label 
        class="tag-label-div" 
        *ngIf="!inputParameters.required" 
        [ngClass]="{ 'disabled-label': booleanFormControl.disabled }">{{inputParameters.name}}</mat-label>

        <!--
        <mat-label class="tag-label-div is-required" *ngIf="!readOnly && inputParameters.required">{{inputParameters.tag}}</mat-label>
        <mat-label class="tag-label-div-read-only is-required" *ngIf="readOnly && inputParameters.required" >{{inputParameters.tag}}</mat-label>
    
        <mat-label class="tag-label-div" *ngIf="!readOnly && !inputParameters.required">{{inputParameters.tag}}</mat-label>
        <mat-label class="tag-label-div-read-only" *ngIf="readOnly && !inputParameters.required" >{{inputParameters.tag}}</mat-label>
        -->
      
        <div class="radio-buttons-div" [formGroup]="formControlGroup">
    
            <mat-radio-group 
                color="primary"
                class="radio-group"
                formControlName="value">
                <mat-radio-button 
                    #currentButton
                    class="radio-button" 
                    *ngFor="let value of possibleValues ; let i = index" 
                        [value]=possibleValues[i].value 
                        [checked]=possibleValues[i].checked
                        (click)="radioButtonClicked_standard($event, currentButton)"
                        [style.pointer-events]="booleanFormControl.disabled ? 'none' : 'auto'" >
                    {{value.tag}}
                </mat-radio-button>
            </mat-radio-group>
            <!-- 
            formControlName:    the name of the specific formControl inside the formGroup (the one with the output result)
            [value]:            current value that will be recieved and stored in formControlName
            (change)="handleChange($event)":    this can go inside <mat-radio-button> to detect changes
            [disabled]="readOnly"
            -->
    
        </div>
               
    </div> 
