import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

// Service for user authentication
import { LanguageService } from '../language/language.service';

// User authentication service
import { UserAuthenticationService } from '../firestore/user-authentication.service';

// Utils service
import { UtilsService } from '../utils/utils.service';

// Backend data service
import { BackendDataService } from '../backend-data/backend-data.service';

// Constants
import { GlobalConstant } from 'src/app/constants/global-constant';


@Injectable({
  providedIn: 'root'
})
export class ControllerService {

  // study: loads an specific study template and information
  // patientSearching: display the option for looking for an specific patient inside a study
  // landingPage: Selects the target study 

  public defaultAction:string='landingPage' // 'study', 'landingPage', 'patientSearching'
  private patientId:string = null;
  private formLocked:boolean = false;

  private currentStudyId:string = ''


  // Observable for the actions
  public currentAction:BehaviorSubject<any>


  constructor(public languageService: LanguageService,
            public userAuthenticationService:UserAuthenticationService,
            private router: Router,
            //public httpService:HttpService,
            public utilsService:UtilsService,
            public backendDataService:BackendDataService) 
  {
    // Set the default language
    this.languageService.setLanguage(GlobalConstant.defaultLanguage)

    // Observable for the actions 
    this.currentAction = new BehaviorSubject<any>(this.defaultAction)

    // Subscribe to the user authentication service and checking if the user is logged in or not
    this.subscribeToUserLoginState()
  }




  // ------ Actions bar actions observable ----//

  // Methods for updating the observable that indicates what component should be displyed according to the seleccted action
  setCurrentAction(newValue:any): void {
    this.currentAction.next(newValue);
  }
  getGetCurrentAction(): Observable<any> {
    return this.currentAction.asObservable();
  }

  // Methods for updating the patient id
  setPatientId(newValue:string): void {
    this.patientId = newValue;
  }
  getPatientId(): string {
    return this.patientId;
  }

  setFormLocked(newValue:boolean): void {
    this.formLocked = newValue;
  }
  getFormLocked(): boolean {
    return this.formLocked;
  }


  // ------ Methods related to the login/logout process ----//

  // Methods related to the login/logout process
  logout(){
    this.userAuthenticationService.logout()
  }
  // Method for subscribing to the user authentication service and checking if the user is logged in or not  
  async subscribeToUserLoginState(){
    await this.userAuthenticationService.getUserIsLoggedValue().subscribe(async (userIsLogged:boolean) => {
      if(userIsLogged){
        //this.setCurrentAction('landingPage')
        this.router.navigate(['']);
      }else{
        //this.setCurrentAction('landingPage')
        this.router.navigate(['/login']);
      }
    });
  }

  // ----- Study template methods ----- //

  getStudyList(){
    return this.userAuthenticationService.getAuthorizedUserDict()['projects']
  }

  setStudy(studyId:string){
    this.currentStudyId = studyId
    this.setCurrentAction('patientSearching')
  }

  getStudyTemplate(){
    return GlobalConstant.studies[this.currentStudyId].template
  }

  getStudyName(){
    return GlobalConstant.studies[this.currentStudyId].name
  }
  

  // ------ Methods related to the backend data ----//

  async getExcelInformation(){
    return this.backendDataService.getExcelInformation(this.currentStudyId)
  }

  async getPatientIdList(){
    return this.backendDataService.getPatientIdList(this.currentStudyId)
  }

  async verifyPatientExists(patientId:string){
    return this.backendDataService.verifyPatientExists(this.currentStudyId, patientId)
  }

  async addNewPatient(patientId:string){
    this.setPatientId(patientId);
    this.setFormLocked(false);
    return this.backendDataService.addNewPatient(this.currentStudyId, patientId)
  }

  async findPatient(patientId:string){
    this.setPatientId(patientId);
    this.setFormLocked(true);
    return this.backendDataService.findPatient(this.currentStudyId, patientId)
  }

  async updatePatientInformation(patientId:string, tabId:string, panelId:string, panelDataDict:any, 
    targetCallbackMethod: (data: any) => void
  ){
    return this.backendDataService.updatePatientInformation(this.currentStudyId, patientId, tabId, panelId, panelDataDict, 
      targetCallbackMethod
    )
  }


  async deletePatient(patientId:string){
    await this.backendDataService.deletePatient(this.currentStudyId, patientId)
  }


 

}
