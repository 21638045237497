
import { Injectable } from '@angular/core';

import { StringsES } from '../../constants/strings-es'
import { StringsEN } from '../../constants/strings-en'

import { GlobalConstant } from 'src/app/constants/global-constant';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public Strings:any = {}
  public languageCode:string = GlobalConstant.defaultLanguage

  constructor() { }

  setLanguage(language:string){
    
    if(language == 'es'){
      // Spanish
      this.Strings =  StringsES
      this.languageCode = 'es'
    }else if (language == 'en'){
      // English
      this.Strings = StringsEN
      this.languageCode = 'en'
    }
  }


}
