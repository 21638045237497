import { Component, OnInit } from '@angular/core';

import {FormControl, FormGroupDirective, NgForm, FormGroup} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';




/* Error when invalid control is dirty, touched, or submitted (input fields) */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    //const isSubmitted = form && form.submitted;
    //return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    return !!(control && control.invalid && (control.dirty || control.touched ));
  }
}




@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.css']
})
export class DateFieldComponent implements OnInit {


  // Input parameters. Pass through the reference when creating the view
  public inputParameters:any = {}
  public inputData:any = {}

  
  // Individual form controls and error matcher
  formControlGroup:any;
  dateFormControl = new FormControl(null, []); 
  matcher = new MyErrorStateMatcher();



  constructor() { }

  ngOnInit(): void {

    // Create the form control group
    this.formControlGroup = new FormGroup({
      value: this.dateFormControl
    })
        
    // Set the current value   
    if(this.inputData){
      /*
      if(this.inputData.value){
        this.dateFormControl.setValue(this.inputData.value)
      }*/
      if(this.inputData.value){
        const dateInMilliseconds = this.inputData.value.seconds * 1000;
        const dateValue = new Date(dateInMilliseconds);
        this.dateFormControl.setValue(dateValue)
      }
    }

  }


  



  // ----- Custom validators ------ //





  // ----- Functions to return the corresponding information to parent class ------ //

  getResultDict(){
    let resultDict = {
      key: this.inputParameters.key,
      type: this.inputParameters.type,
      value:this.formControlGroup.value.value
    }
    return resultDict
  }



}
