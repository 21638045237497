import { Component, OnInit } from '@angular/core';


import {FormControl, FormGroupDirective, NgForm, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';


// Services
import { ControllerService } from 'src/app/services/controller/controller.service';
import { LanguageService } from 'src/app/services/language/language.service'
import { UtilsService } from 'src/app/services/utils/utils.service';

// Constants
import { GlobalConstant } from 'src/app/constants/global-constant';

// Dialog popups
import { MatDialog } from '@angular/material/dialog';

// Popups
import { DecisionPopupComponent } from '../../popups/decision-popup/decision-popup.component';

/* Error when invalid control is dirty, touched, or submitted (input fields) */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}



@Component({
  selector: 'app-study',
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.css']
})
export class StudyComponent implements OnInit {

  // Waiting popup info
  displayWaitingSpiner = false
  waitingMessage = ''

  // Patient form
  displayPatient:boolean = false
  public tabList:any = {} //GlobalConstant.study.studyTemplate
  public studyData:any = {}
  public patientIdList:any = []
  public excelTimestamp:string = ''
  

  // Individual form controls and error matcher
  formControl_identifier = new FormControl('', [
    Validators.required,
    this.specialCharactersValidators.bind(this),
    this.spacesInStringValidators.bind(this),
  ]); 
  matcher = new MyErrorStateMatcher();
  // Form control group
  formControlGroup_identifier = this._formBuilder.group({
    identifier: this.formControl_identifier,
  }, 
  {});



  constructor(public languageService: LanguageService,
              public utilsService: UtilsService,
              public controllerService:ControllerService,
              private _formBuilder:FormBuilder,
              public dialog: MatDialog,) { }


  ngOnInit(): void {
    // Get staudy template
    this.tabList = this.controllerService.getStudyTemplate()
    // Initialize patient information
    this.initializePatientsInformation()
  }

  // ----- Initialization methods ----- //
  initializePatientsInformation(){
    // Obtener lista de pacientes existentes
    this.getPatientIdList()
    // Obtener la última fecha de actualización del Excel
    this.getExcelTimestamp()
  }


  // ----- Custom validators ------ //

  specialCharactersValidators(control: AbstractControl) {
    let currentString = control.value
    if(currentString){
      if(currentString.length > 0){
        if(!GlobalConstant.study.addmittedCharacters.test(currentString)){
          return { specialCharactersValidationError: true };
        }
      }
    }
    return null;
  }

  spacesInStringValidators(control: AbstractControl) {
    let currentString = control.value
    if(currentString){
      if(currentString.length > 0){
        if(currentString.indexOf(' ') >= 0){
          return { spacesInStringValidationError: true };
        }
      }
    }
    return null;
  }







  // ----- Finding / adding patients methods //

  button_back(){
    // Stop displaying the patient information
    this.displayPatient = false
    this.controllerService.setPatientId(null);
    // Clear the study data
    this.studyData = {}
    // Carry out initialization methods to update patient's list and xlsx timestamp
    this.initializePatientsInformation()
    // Clear the value set in the identifier box
    this.formControl_identifier.setValue('');
  }


  async button_delete(){
    // TODO: TBD --------------- Display decision popup
    //this.button_delete_callback(true)
    let patientId = this.formControl_identifier.value
    // Open the popup
    this.dialog.open(
      DecisionPopupComponent, 
      {
        disableClose: true,
        data: {
            popupTitle:this.languageService.Strings.landingPageStrings.deletePatientConfirmationTitle + ` ${patientId}`, 
            popupText:this.languageService.Strings.landingPageStrings.deletePatientConfirmationText},
      } 
    ).afterClosed().subscribe(result => this.button_delete_callback(result))
  }
  async button_delete_callback(shouldDeletePatient:boolean){
    // If unintentional, return
    if(!shouldDeletePatient) return
    // Delete patient
    // Get the patient id
    let patientId = this.formControl_identifier.value
    // TODO: TBD ------------ Display waiting popup

    // Delete the patient information in database
    await this.controllerService.deletePatient(patientId);
    // TODO: TBD ------------ Stop waiting popup

    // TODO: TBD ------------ Manage result
    
    // Clear the study data
    this.studyData = {}
    // Carry out initialization methods to update patient's list and xlsx timestamp
    this.initializePatientsInformation()
    // Clear the value set in the identifier box
    this.formControl_identifier.setValue('');
    // Stop displaying the patient information (return to the patient selecction view)
    this.displayPatient = false
  }


  async buttonClicked_newPatient(){
    // Display waiting popup
    this.waitingMessage = this.languageService.Strings.landingPageStrings.waitingMessage_NewPatient
    this.displayWaitingSpiner = true
    // Get the patient id
    let patientId = this.formControl_identifier.value
    // Verify that the patient deos not exist in the db
    let patientExists = await this.controllerService.verifyPatientExists(patientId)
    // If patient exists:
    if(patientExists){
      // Stop the waiting popup
      this.displayWaitingSpiner = false
      // Notify the user
      this.utilsService.displayInformationPopup(
        this.languageService.Strings.landingPageStrings.informationPopup_userAlreadyExists_title,
        this.languageService.Strings.landingPageStrings.informationPopup_userAlreadyExists_message)
      // Finish the process
      return
    }
    // Register the patient
    let resultDict = await this.controllerService.addNewPatient(patientId)
    // Stop the waiting popup
    this.displayWaitingSpiner = false
    // Update the user interface depending on the result
    //  Error
    if(resultDict['resultOk'] == false){
      this.utilsService.displayInformationPopup(
        this.languageService.Strings.landingPageStrings.informationPopup_error_title,
        resultDict['error'])
      return
    }
    //  Success
    this.displayPatient = true
    // Carry out initialization methods to update patient's list and xlsx timestamp
    this.initializePatientsInformation()
  }

  async buttonClicked_accessPatient(){
    // Display waiting popup
    this.waitingMessage = this.languageService.Strings.landingPageStrings.waitingMessage_FindPatient
    this.displayWaitingSpiner = true
    // Get the patient id
    let patientId = this.formControl_identifier.value
    // Get the patient information
    let resultDict = await this.controllerService.findPatient(patientId)
    // Stop the waiting popup
    this.displayWaitingSpiner = false
    // Verify that the patient exists (the dictionary cannot be undefined)
    //  Patient does not exists
    if(resultDict === undefined){
      this.utilsService.displayInformationPopup(
        this.languageService.Strings.landingPageStrings.informationPopup_patientDoesNotExist_title,
        this.languageService.Strings.landingPageStrings.informationPopup_patientDoesNotExist_message)
      return
    }
    //  Patient exists
    this.studyData = resultDict
    this.displayPatient = true
  }

  async getPatientIdList(){
    this.patientIdList = await this.controllerService.getPatientIdList()
  }

  async buttonClicked_FillPatientIdInputBox(id:string){
    // Escribir en el formulario de entrada el ID del paciente elegido
    this.formControl_identifier.setValue(id)
  }

  async buttonClicked_downloadExcel(){
    // Obtener la última fecha de actualización del Excel
    let excelInfoDoc = await this.controllerService.getExcelInformation()
    if(!(excelInfoDoc === undefined)){
      let excelTimestamp = excelInfoDoc['timestamp']
      let excelDownloadUrl = excelInfoDoc['downloadUrl']
      let excelDatabase = excelInfoDoc['database']
      let excelName = (excelDatabase + '_' + excelTimestamp).replace(/ /g, "_").replace(/:/g, "-") + ".xlsx"
      // Download Excel
      try {
        this.controllerService.utilsService.downloadFile(excelDownloadUrl, excelName);
      } catch(error){
        this.controllerService.utilsService.displaySnackBar(this.languageService.Strings.errorMessages.error_downloadExcel);
      }

    } else{
      this.controllerService.utilsService.displaySnackBar(this.languageService.Strings.errorMessages.error_excelNotAvailable);
    }
    
  }

  // Comprueba si el paciente escrito en Input Box está en la lista de pacientes.
  // Esta función se utiliza para habilitar o deshabilitar el botón de "añadir paciente"
  // cuando se elije un paciente a través de la lista de pacientes
  inputPatientInList(): boolean {
    return this.patientIdList.includes(this.formControl_identifier.value)
  }

  async getExcelTimestamp(){
    // Obtener la última fecha de actualización del Excel
    let excelInfoDoc = await this.controllerService.getExcelInformation()
    if(!(excelInfoDoc === undefined)){
      this.excelTimestamp = excelInfoDoc['timestamp']
    } else {
      this.excelTimestamp = ''
    }
  }








  async submitEvent_formDataSubmitted(eventDict){
    // Display waiting popup
    this.waitingMessage = this.languageService.Strings.landingPageStrings.waitingMessage_UpdatePatient
    this.displayWaitingSpiner = true
    // Get the event data
    let tabKey = eventDict['tabKey']
    let panelKey = eventDict['panelKey']
    let panelData = eventDict['panelData']
    // Get the patient id
    let patientId = this.formControl_identifier.value
    // Update the information in the backend
    let resultDict = await this.controllerService.updatePatientInformation(patientId, tabKey, panelKey, panelData)
    // Stop the waiting popup
    this.displayWaitingSpiner = false
    // Manage errors
    if(resultDict['resultOk'] == false){
      this.utilsService.displayInformationPopup(
        this.languageService.Strings.landingPageStrings.informationPopup_error_title,
        resultDict['error'])
      return
    }
  }
}
