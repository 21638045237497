<div class="input_box">

    <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
    
        <mat-label>{{inputParameters.name}}</mat-label>

        <!-- [readonly]="readOnly" -->
        <input 
            *ngIf="!inputParameters.multiline"
            type="text" 
            placeholder="" 
            maxlength="GlobalConstant.textField.maxLength"
            matInput
            [formControl]="textFormControl"
            [errorStateMatcher]="matcher" 
            autocomplete="off">
        
        <textarea 
            *ngIf="inputParameters.multiline"
            type="text" 
            placeholder="" 
            maxlength="GlobalConstant.textField.maxLength"
            matInput
            [formControl]="textFormControl"
            [errorStateMatcher]="matcher" 
            autocomplete="off">
        </textarea>
        
        <mat-error *ngIf="textFormControl.hasError('maxlength')">
            {{languageService.Strings.errorMessages.error_maxLength}}
        </mat-error>
        <mat-error *ngIf="textFormControl.hasError('specialCharactersValidationError')">
            {{languageService.Strings.errorMessages.error_specialCharacters}}
        </mat-error>
    
    </mat-form-field>


    
  </div> 
