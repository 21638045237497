<div class="input_box">
    
    <mat-form-field appearance="fill" [style.width.%]=100>
  
        <mat-label>{{inputParameters.name}}</mat-label>
        
        <!--
            type="date" 
        -->

        <input 
            *ngIf="inputParameters.required" 
            
            min={{inputParameters.minValue}} 
            max={{inputParameters.maxValue}} 
            step={{inputParameters.stepValue}} 
            placeholder="" 
            matInput   [matDatepicker]="picker"
            [formControl]="dateFormControl"
            [errorStateMatcher]="matcher" 
            required
            autocomplete="off">

        <input 
            *ngIf="!inputParameters.required" 

            min={{inputParameters.minValue}} 
            max={{inputParameters.maxValue}} 
            step={{inputParameters.stepValue}} 
            placeholder="" 
            matInput   [matDatepicker]="picker"
            [formControl]="dateFormControl"
            [errorStateMatcher]="matcher" 
            autocomplete="off">
            
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>


            <!--
                <mat-form-field>
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="picker">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            -->
        
        <!--
        <mat-error *ngIf="(inputParameters.required) && (dateFormControl.hasError('required'))">
        {{errorMessages.error_required}}
        </mat-error>
        -->
  
      
    </mat-form-field>
    
  </div> 