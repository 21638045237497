import { Component, OnInit } from '@angular/core';

// services 
import { ControllerService } from 'src/app/services/controller/controller.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { LanguageService } from 'src/app/services/language/language.service';

// Constants
import { GlobalConstant } from 'src/app/constants/global-constant';



@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {


  studyList:any = []
  studyInfoDict:any = GlobalConstant.studies

  constructor(public controllerService: ControllerService,
    public utilsService: UtilsService,
    public languageService: LanguageService
  ) {}



  ngOnInit(): void {
    this.studyList = this.controllerService.getStudyList()
  }


  // ----- Button actions ----- //

  button_logout() {
    this.controllerService.logout()
  }

  selectStudy(studyId:string){
    this.controllerService.setStudy(studyId)    
  }

}
