<!-- Visualizing view -->
<div class="global-background">



    <div class="global-container" *ngIf="!displayPatient">

        <!-- Searching patient or creating new patient method -->
        <!-- keyboardPress: Set custom functionality on keyboard press (i.e: prevent popup clonsing when enter is pressed)" -->
        <!-- (keypress)="keyboardPress($event) | (keydown)="keyboardPress($event) | (keyup)="keyboardPress($event) -->
        <form [formGroup]="formControlGroup_identifier" class=global-form >

            <div class="input_box">
                <mat-form-field class="input-text noraml-text-size" appearance="fill">
                    <mat-label>
                        {{languageService.Strings.searchingBox.input_box_label_patientId}}
                    </mat-label>
                    <input class="input-test"
                        matInput
                        id="identifier"
                        type="text" 
                        placeholder="" 
                        [formControl]="formControl_identifier" 
                        [errorStateMatcher]="matcher">

                    <!-- Find patient button --> 
                    <button mat-icon-button matSuffix 
                        color="primary" 
                        class="search_button"
                        [matMenuTriggerFor]="patientIdMenu">
                        <mat-icon>search</mat-icon>
                    </button>

                    <mat-menu #patientIdMenu="matMenu" xPosition="after">
                        <ng-container *ngFor="let id of patientIdList">
                            <button mat-menu-item (click)="buttonClicked_FillPatientIdInputBox(id)">
                                {{ id }}
                            </button>
                        </ng-container>
                    </mat-menu>

                    <mat-error *ngIf="formControl_identifier.hasError('spacesInStringValidationError')">
                        {{languageService.Strings.searchingBox.error_spaces}}
                    </mat-error>
                    <mat-error *ngIf="formControl_identifier.hasError('specialCharactersValidators')">
                        {{languageService.Strings.searchingBox.error_specialCharacters}}
                    </mat-error>
                    <!--
                    <mat-error *ngIf="formControl_identifier.hasError('firebaseError')" >
                        {{languageService.Strings.loginPopup.login_error_email_or_passowrd}}
                    </mat-error>
                    -->
                </mat-form-field>
            </div>              


            <div class="base_buttons_container">
                <!-- New patient button --> 
                <button 
                    mat-raised-button 
                    color="primary"
                    class="base_buttons"
                    type="button" 
                    style="width: 100%;"
                    (click)="buttonClicked_newPatient()"
                    [disabled]="(!formControlGroup_identifier.valid) || (inputPatientInList())">
                        {{languageService.Strings.searchingBox.button_newPatient}}
                </button>
                <!-- Access button -->        
                <button 
                    mat-raised-button 
                    color="primary"
                    class="base_buttons"
                    type="button"  
                    style="width: 100%;"
                    (click)="buttonClicked_accessPatient()"
                    [disabled]="(!formControlGroup_identifier.valid) || (!inputPatientInList())">
                        {{languageService.Strings.searchingBox.button_accessPatient}}
                </button>
            </div>

            <!-- Download excel button -->  
            <hr class="custom-hr">
            <div class="text_container" *ngIf="(excelTimestamp !== '')">{{languageService.Strings.landingPageStrings.lastUpdateExcel}}: {{excelTimestamp}}h</div>
            <div class="text_container" *ngIf="(excelTimestamp === '')">{{languageService.Strings.landingPageStrings.lastUpdateExcel}}: </div>
            <div class="base_buttons_container">
                <button 
                mat-raised-button 
                class="base_buttons"
                type="button"  
                style="width: 100%;"
                (click)="buttonClicked_downloadExcel()"
                [disabled]="(excelTimestamp === '')">
                    {{languageService.Strings.searchingBox.button_downloadExcel}}
                    <mat-icon>download</mat-icon>
                </button>
            </div>      
        </form>

    </div>


    

    <!-- Form -->
    <app-global-study *ngIf="displayPatient"
        [mode]="'visualize'"
        [tabList]="tabList"
        [studyData]="studyData"
        (submitEvent)="submitEvent_formDataSubmitted($event)"
    >
    </app-global-study>

    <!-- Back and Delete button -->
    <div class="back-button" color="primary" *ngIf="displayPatient">
        <button 
            mat-raised-button
            color="primary"
            class="base_buttons" 
            (click)="button_back()">
            <mat-icon>arrow_back_ios</mat-icon>
            {{languageService.Strings.landingPageStrings.button_back}}
        </button>

        <button 
            mat-raised-button
            color="primary"
            class="base_buttons" 
            (click)="button_delete()">
            <mat-icon>delete</mat-icon>
            {{languageService.Strings.landingPageStrings.button_delete}}
        </button>
    </div>




</div>




<!-- Waiting spinner -->

<div *ngIf="(displayWaitingSpiner)" >
    <app-waiting-spinner [waitingMessage]="waitingMessage" ></app-waiting-spinner> 
</div>