<div class="input_box">

    <mat-label class="tag-label-div" [ngClass]="{ 'disabled-label': fieldDisabled }">
        {{inputParameters.name}}
    </mat-label>

    <!-- Existing files -->
    <mat-list class="global-list-div" *ngIf="(dicomData != null) && (dicomService.getNumberOfPatients() == 0)">
        <div class="single-item-div-internal">
            <div class="single-item-div-left" [ngClass]="{ 'disabled-label': fieldDisabled }">
                {{languageService.Strings.dicomFieldStrings.information_Uploaded}}: {{dicomData.description}}
            </div>
            <div class="single-item-div-right">
                <button mat-icon-button 
                    color="primary" 
                    (click)="downloadFiles()"
                    matTooltip={{languageService.Strings.landingPageStrings.button_download}}>
                    <mat-icon>cloud_download</mat-icon>
                </button>
                <button mat-icon-button 
                    color="primary" 
                    (click)="deleteFiles()"
                    [disabled]="fieldDisabled"
                    matTooltip={{languageService.Strings.landingPageStrings.button_delete}}>
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </mat-list>


    <!-- Selected files -->
    <mat-list class=global-list-div *ngIf="dicomService.getNumberOfPatients() > 0">

        <!-- Patient list -->
        <div class=single-item-div
            *ngFor="let patientDict of dicomService.getPatientInformationList(); let patientIndex=index">
            <!-- Separator line -->
            <mat-divider *ngIf="patientIndex > 0"></mat-divider>
            <!-- List item -->
            <div class=single-item-div-internal>
                <!-- File information and actions-->
                <div class="single-item-div-left">
                    {{languageService.Strings.dicomFieldStrings.information_Patient}}:
                    {{dicomService.getPatientInformationFromIndex(patientIndex)}}
                </div>
                <div class="single-item-div-right">
                    <!--
                    <mat-checkbox
                        [disabled]="fieldDisabled">
                    </mat-checkbox>
                    -->
                </div>
            </div>
            <!-- Separator line -->
            <mat-divider></mat-divider>

            <!-- Study list -->
            <div class=single-item-div
                *ngFor="let studyDict of dicomService.getStudyInformationList(patientIndex); let studyIndex=index">
                <div class=single-subitem-div-internal>
                    <!-- File information and actions-->
                    <div class="single-item-div-left">
                        {{languageService.Strings.dicomFieldStrings.information_Study}}:
                        {{dicomService.getStudyInformationFromIndex(patientIndex, studyIndex)}}
                    </div>
                    <!--
                    <div class="single-item-div-right">
                        <mat-checkbox
                            [disabled]="fieldDisabled">
                        </mat-checkbox>
                    </div>  
                    -->
                </div>
                <!-- Separator line -->
                <mat-divider></mat-divider>


                <!-- Series list -->
                <div class=single-item-div
                    *ngFor="let seriesDict of dicomService.getSeriesInformationList(patientIndex, studyIndex); let seriesIndex=index">
                    <div class=single-subsubitem-div-internal>
                        <!-- File information and actions-->
                        <div class="single-item-div-left">
                            {{languageService.Strings.dicomFieldStrings.information_Series}}:
                            {{dicomService.getSeriesInformationFromIndex(patientIndex, studyIndex, seriesIndex)}}
                        </div>
                        <div class="single-item-div-right">
                            <mat-checkbox
                                [checked]="(patientIndex===currentPatientIndex)&&(studyIndex===currentStudyIndex)&&(seriesIndex===currentSeriesIndex)"
                                (click)="selectSeries(patientIndex, studyIndex, seriesIndex)"
                                [disabled]="fieldDisabled">
                            </mat-checkbox>
                        </div>
                    </div>
                    <!-- Separator line -->
                    <mat-divider
                        *ngIf="seriesIndex < dicomService.getNumberOfSeries(patientIndex, studyIndex) - 1"></mat-divider>

                </div>
            </div>
        </div>
    </mat-list>

    <app-dir-selector (selectedFilesEvent)="onDirSelected($event)"
        [fileUploaderButtonText]="languageService.Strings.dirSelector.button_selectDirectory"
        [disabled]="fieldDisabled">
    </app-dir-selector>

</div>




<!-- Waiting spinner -->

<div *ngIf="(displayWaitingSpiner)">
    <app-waiting-spinner [waitingMessage]="waitingMessage"></app-waiting-spinner>
</div>