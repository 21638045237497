import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/guards/auth-guard.service';

import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { GlobalTemplateComponent } from './components/templates/global-template/global-template.component';


const routes: Routes = [
  
  { path: '', component: GlobalTemplateComponent, canActivate: [AuthGuardService] }, // , canActivate: [AuthGuardService]
  { path: 'login', component: LoginPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }