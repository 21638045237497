import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.css']
})
export class ProgressIndicatorComponent {

  // Input parameters. Pass from the caller class binding the input through the HTML
  @Input() waitingMessage:string = '';
  @Input() progressIndicator:number = 0;

  constructor() { }

}

