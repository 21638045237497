//import { environment } from "src/environments/environment";

import studyTemplateJson_ree from './../../app/constants/json-files/ree.json';
import studyTemplateJson_cancercolon from './../../app/constants/json-files/cancercolon.json';
import studyTemplateJson_cancergastrico from './../../app/constants/json-files/cancergastrico.json';
import studyTemplateJson_trifasico from './../../app/constants/json-files/trifasico.json';
import studyTemplateJson_mdrtraining from './../../app/constants/json-files/mdrtraining.json';
import studyTemplateJson_valonc from './../../app/constants/json-files/valonc.json';
import studyTemplateJson_onco12o from './../../app/constants/json-files/onco12o.json';
import studyTemplateJson_abbottcolonhmb from './../../app/constants/json-files/abbottcolonhmb.json';
import studyTemplateJson_obesidad from './../../app/constants/json-files/obesidad.json';
import studyTemplateJson_cancerovario from './../../app/constants/json-files/cancerovario.json';
import studyTemplateJson_cancermamasr from './../../app/constants/json-files/cancermamasr.json';
import studyTemplateJson_mucositissr from './../../app/constants/json-files/mucositissr.json';
import studyTemplateJson_pantheia from './../../app/constants/json-files/pantheia.json';

//import studyTemplateJson_cancermamasr from './../../app/constants/json-files/test.json';

export const GlobalConstant = {
    fileLimitPerSingleZip: 2500,
    fileDownloadingConcurrency: 8,  // 5 debería ser aceptado por todos los navegadores
    fileUploadingConcurrency: 5,  // 5 debería ser aceptado por todos los navegadores
    defaultLanguage: 'en',
    allowBadlyAnonimizedDcm_Patient: true,
    allowBadlyAnonimizedDcm_Study: false,
    allowBadlyAnonimizedDcm_Series: false,
    badlyAnonymizedId: 'Incorrectly anonymized',
    studies: {
        cancercolon: {
            template: studyTemplateJson_cancercolon,
            name: 'Cancer de colon',
        }, 
        cancergastrico: {
            template: studyTemplateJson_cancergastrico,
            name: 'Cancer gástrico'
        }, 
        ree: {
            template: studyTemplateJson_ree,
            name: 'Resting Energy Expenditure'
        }, 
        mdrtraining: {
            template: studyTemplateJson_mdrtraining,
            name: 'MDR Training'
        }, 
        trifasico: {
            template: studyTemplateJson_trifasico,
            name: 'Trifásico'
        }, 
        valonc: {
            template: studyTemplateJson_valonc,
            name: 'Valonc'
        },
        onco12o: {
            template: studyTemplateJson_onco12o,
            name: 'Onco 12 de Octubre'
        }, 
        abbottcolonhmb: {
            template: studyTemplateJson_abbottcolonhmb,
            name: 'Abbott colom HMB'
        },
        obesidad: {
            template: studyTemplateJson_obesidad,
            name: 'Obesidad'
        },
        cancerovario: {
            template: studyTemplateJson_cancerovario,
            name: 'Cancer de ovario'
        },
        cancermamasr: {
            template: studyTemplateJson_cancermamasr,
            name: 'Cancer de mama (SR)'
        },
        mucositissr: {
            template: studyTemplateJson_mucositissr,
            name: 'Mucositis (SR)'
        },
        pantheia: {
            template: studyTemplateJson_pantheia,
            name: 'Estudio Phanteia'
        },
    },
    firestore: {
        collection_authorizedUsers: 'authorizedUsers',
        collection_patientId: 'patientId',
        collection_stableInfo: 'stable',
        collection_updatedInfo: 'update',
        collection_synchronizationInfo: 'synchronization',
        collection_excelInfo: 'excel',
        document_excelInfo: 'excelInfo',
        limit_numberOfDicomFilesInMainDict: 10,
        key_largeDocumentMovedToStorage: 'largeDocument',
        key_largeDocumentFileName: 'files.json'
    },
    formField: {
        textField: {
            maxLength: 50, 
            addmittedCharacters: /^[a-zA-Z0-9\t\n ./<>¿?;:"'`¡!@#$%^&*()\[\]{}_+=ºªñÑ,·çüÜ|\\-áéíóúÁÉÍÓÚ]*$/,
        },
    },
    study:{
        addmittedCharacters: /^[a-zA-Z0-9_]*$/,
    },
    dicom: {
        filenamesToAvoid: [ // Files to avoid
            'DICOMDIR', 
            '.DS_Store', 
            '._.DS_Store'],
        dicomKeysToKept: [ // DICOM metadata to be kept when anonymizing the images
            '_vrMap',
            'SOPClassUID',  
            'TransferSyntaxUID',
            'ImageType', 
            'Modality',
            'StudyDate',
            'SliceThickness', 
            'PixelSpacing', 
            'SeriesNumber', 
            'ImagePositionPatient',
            'Rows', 
            'Columns', 
            'BitsAllocated', 
            'BitsStored', 
            'RescaleIntercept', 
            'RescaleSlope', 
            'HighBit', 
            'PixelRepresentation', 
            'SmallestImagePixelValue', 
            'LargestImagePixelValue',
            'PhotometricInterpretation',
            'PatientSex', 
            'PatientAge', 
            'PatientWeight',
            'PatientSize',
            'PixelData',
            'SamplesPerPixel', 
            'RescaleType',
            'SpacingBetweenSlices',
            // Additional metadata to be used for software validation
            //  Adquisition parameters for software validation
            'SoftwareVersions', 'ConvolutionKernel', 'KVP', 'XRayTubeCurrent', 'ExposureTime',
            // Manufacturer parameters for software validation     
            'Manufacturer', 'ManufacturerModelName', 
            // Contrast parameters
            'ContrastBolusRoute', 'ContrastBolusAgent',
          ],
        dicomKeysToModify: [ // DICOM metadata to be modified when pseudonymizing the images
            'PatientID',
            'StudyID',
            // Unique ids for being able to manage the data --- TODO: Validate this ----
            'StudyInstanceUID', 'SeriesInstanceUID', 'SOPInstanceUID',
          ],
    },
    focusedonFileTypes: {
        bc_abd: ['.xlsx', '.pdf', '.fcsdn'],
        bc_abd_3d: ['.xlsx', '.pdf', '.fcsdn'],
        bc_neck: ['.xlsx', '.pdf', '.fcsdn'],
    },
}


     
