import { Component, OnInit, ComponentFactoryResolver, ViewContainerRef, Renderer2  } from '@angular/core';

// Global controller service
import { ControllerService } from '../../../services/controller/controller.service';

// Pages
import { LandingPageComponent } from '../../pages/landing-page/landing-page.component';
import { PatientSearchingPageComponent } from '../../pages/patient-searching-page/patient-searching-page.component';
import { StudyComponent } from '../../pages/study/study.component';


@Component({
  selector: 'app-global-template',
  templateUrl: './global-template.component.html',
  styleUrls: ['./global-template.component.css']
})
export class GlobalTemplateComponent implements OnInit {

  private currentPageView:any
  private currentAction:string = "none"


  constructor (public viewContainerRef: ViewContainerRef,
              private componentFactoryResolver: ComponentFactoryResolver,
              private renderer: Renderer2,
              public controllerService:ControllerService) { }

  ngOnInit(): void {
    // Subscribe to available actions execution
    this.subscribeToActions()
  }



  loadComponent(viewContainerRef:ViewContainerRef, component:any){
    return viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(component))        
  }
  clearComponent(viewContainerRef: ViewContainerRef){
    viewContainerRef.clear();
  }
  clearChildComponent(component:any){
    component.hostView.destroy()
  }



  // Method for subscribing to the selected action to know what component should be displyed
  subscribeToActions(){
    this.controllerService.getGetCurrentAction().subscribe((currentAction) => {
        // Clear the view
        this.clearComponent(this.viewContainerRef)
        // Add the main view
        if(currentAction == "landingPage"){
          this.currentPageView = this.loadComponent(this.viewContainerRef, LandingPageComponent) 
        }else if(currentAction == "study"){
          this.currentPageView = this.loadComponent(this.viewContainerRef, StudyComponent) 
        }else if(currentAction == "patientSearching"){
          this.currentPageView = this.loadComponent(this.viewContainerRef, PatientSearchingPageComponent)
        }

        // Store the new action
        this.currentAction = currentAction
      }
    );
  }

}
