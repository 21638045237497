import { Component, OnInit } from '@angular/core';

import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-boolean-field',
  templateUrl: './boolean-field.component.html',
  styleUrls: ['./boolean-field.component.css']
})
export class BooleanFieldComponent implements OnInit {


  // Input parameters. Pass through the reference when creating the view
  public inputParameters:any = {}
  public inputData:any = {}


  // Form data dictionray
  public formData: any;
  public formControlGroup:any;

  booleanFormControl = new FormControl('', []); 

  // Posible values // TODO: TBD ------------------------------------------------------------
  possibleValues:any = [
    {tag:'NO', checked:false, value:false},
    {tag:'YES', checked:false, value:true}
  ]


  // Variable that controls if the form is read only or not
  //public readOnly:boolean = false;

  constructor() { }

  ngOnInit(): void {
    // Form control group
    this.formControlGroup = new FormGroup({
      value: this.booleanFormControl,
    })
    // Set the current value 
    this.possibleValues[0].checked = false 
    this.possibleValues[1].checked = false  
    if(this.inputData){
      if(this.inputData.value != null){
        if(this.inputData.value){
          this.possibleValues[1].checked = true 
        }else{
          this.possibleValues[0].checked = true
        }        
      }
    }
    // Set default value
    for(let value of this.possibleValues){
      if(value.checked){
        this.booleanFormControl.setValue(value.value)
      } else {
        this.booleanFormControl.setValue(null)
      }
    }
  }




  getCorrespondingValue(index:number){
    return this.possibleValues[index].value
  }





  // Manage actions clicking the radio butotns (neede to allow "uncheck selected")
  radioButtonClicked_standard(event:any, currentButton:any){
    // Remove the default behaviour to allow unselect
    // event.preventDefault()
    // Manage the input actions
    if (this.booleanFormControl.value === currentButton.value) {
      currentButton.checked = false
      this.booleanFormControl.setValue(null)
    } else {
      currentButton.checked = true
      this.booleanFormControl.setValue(currentButton.value)
    }
  }












  // ----- Custom validators ------ //






  // ----- Functions to return the corresponding information to parent class ------ //

  getResultDict(){
    let resultDict = {
      key: this.inputParameters.key,
      type: this.inputParameters.type,
      value:this.formControlGroup.value.value
    }
    return resultDict
  }




}
