
export const environment = {
  // Firebase credentials
  firebaseConfig: {
    apiKey: "AIzaSyA7x3lPPyKTVrQeSbYSSHxlwX4qX7EzHPE",
    authDomain: "researchapp-st-dev.firebaseapp.com",
    projectId: "researchapp-st-dev",
    storageBucket: "researchapp-st-dev.appspot.com",
    messagingSenderId: "1012100202383",
    appId: "1:1012100202383:web:327b548e47d16d545015fa",
    measurementId: "G-YBPF2HVM05"
  },
  // Production  
  production: false
};


