import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';



// Angular
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


// Firebase
import { environment } from '../environments/environment';
//    This is required for AngularFire
import { AngularFireModule } from '@angular/fire/compat';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
//    This is required for firebase auth
import { provideAuth, getAuth } from '@angular/fire/auth';
//    This is required for firestore
import { provideFirestore, getFirestore } from '@angular/fire/firestore';



// Material design
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';


// Directives
import { ItemDirective } from './directives/item.directive';


// Custom components
import { GlobalTemplateComponent } from './components/templates/global-template/global-template.component';
import { GlobalFormComponent } from './components/form-fields/global-form/global-form.component';

import { GlobalStudyComponent } from './components/form-fields/global-study/global-study.component';

import { TextFieldComponent } from './components/form-fields/specific-fields/text-field/text-field.component';
import { BooleanFieldComponent } from './components/form-fields/specific-fields/boolean-field/boolean-field.component';
import { NumericFieldComponent } from './components/form-fields/specific-fields/numeric-field/numeric-field.component';
import { CategoricalFieldComponent } from './components/form-fields/specific-fields/categorical-field/categorical-field.component';
import { DateFieldComponent } from './components/form-fields/specific-fields/date-field/date-field.component';

import { FileSelectorComponent } from './components/utils/file-selector/file-selector.component';
import { StudyComponent } from './components/pages/study/study.component';
import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { LandingPageComponent } from './components/pages/landing-page/landing-page.component';
import { InformationPopupComponent } from './components/popups/information-popup/information-popup.component';
import { WaitingSpinnerComponent } from './components/utils/waiting-spinner/waiting-spinner.component';
import { FileFieldComponent } from './components/form-fields/specific-fields/file-field/file-field.component';
import { DicomFieldComponent } from './components/form-fields/specific-fields/dicom-field/dicom-field.component';
import { DirSelectorComponent } from './components/utils/dir-selector/dir-selector.component';
import { DecisionPopupComponent } from './components/popups/decision-popup/decision-popup.component';
import { FullDicomFieldComponent } from './components/form-fields/specific-fields/full-dicom-field/full-dicom-field.component';
import { PatientSearchingPageComponent } from './components/pages/patient-searching-page/patient-searching-page.component';
import { DicomStudyFieldComponent } from './components/form-fields/specific-fields/dicom-study-field/dicom-study-field.component';
import { FocusedonFieldComponent } from './components/form-fields/specific-fields/focusedon-field/focusedon-field.component';
import { ProgressIndicatorComponent } from './components/utils/progress-indicator/progress-indicator.component';









@NgModule({
  declarations: [
    AppComponent,
    // Directives
    ItemDirective,
    // Components
    GlobalTemplateComponent,
    GlobalFormComponent,
    GlobalStudyComponent,
    TextFieldComponent,
    BooleanFieldComponent,
    NumericFieldComponent,
    CategoricalFieldComponent,
    DateFieldComponent,
    FileSelectorComponent,
    StudyComponent,
    LoginPageComponent,
    LandingPageComponent,
    InformationPopupComponent,
    WaitingSpinnerComponent,
    FileFieldComponent,
    DicomFieldComponent,
    DirSelectorComponent,
    DecisionPopupComponent,
    FullDicomFieldComponent,
    PatientSearchingPageComponent,
    DicomStudyFieldComponent,
    FocusedonFieldComponent,
    ProgressIndicatorComponent,
  ],
  imports: [
    // Firebase modules
    //    This is required for AngularFire
    AngularFireModule.initializeApp(environment.firebaseConfig),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    //    This is required for firebase auth
    provideAuth(() => getAuth()),
    //    This is required for firestore
    provideFirestore(() => getFirestore()),
    // Non-Firebase related...
    BrowserModule,
    AppRoutingModule,
    // Material design modules
    MatTabsModule,
    MatExpansionModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatListModule,
    DragDropModule,
    MatTooltipModule,
    MatMenuModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    // Rest of modules
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
