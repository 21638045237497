
<input 
    id="file-input"
    type="file" 
    multiple
    #fileUpload
    class="file_input"
    (change)="onFileSelected($event)"
    (click)="onFileClick($event)"
    [accept]="fileUploaderFileType"
>


<div class="file_upload">

<button 
    mat-flat-button 
    color="primary"
    class="base_buttons"
    [disabled]="disabled"
    (click)="fileUpload.click()">
    <mat-icon class="icon">insert_drive_file</mat-icon>
        {{fileUploaderButtonText}}
</button>

<!--
<button 
    mat-button 
    class="base_buttons"
    color="primary" 
    (click)="fileUpload.click()">
        {{fileUploaderButtonText}}
        <mat-icon>file_upload</mat-icon>
</button>
-->

</div>