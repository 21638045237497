import { Component } from '@angular/core';

import { ControllerService } from 'src/app/services/controller/controller.service';
import { LanguageService } from 'src/app/services/language/language.service'
import { UtilsService } from 'src/app/services/utils/utils.service';

import { GlobalConstant } from 'src/app/constants/global-constant';


@Component({
  selector: 'app-focusedon-field',
  templateUrl: './focusedon-field.component.html',
  styleUrls: ['./focusedon-field.component.css']
})
export class FocusedonFieldComponent {


  // Input parameters. Pass through the reference when creating the view
  public inputParameters:any = {}
  public inputData:any = {}

  fieldDisabled: boolean;

  selectedFilesList:any = []
  filesList:any = []

  currentFileSelectorString:string = this.languageService.Strings.fileSelector.button_selectFiles
  currentFileSelectorType:string = '' //'application/json, image/png' //".json, .png"
  

  constructor(public controllerService:ControllerService,
              public languageService:LanguageService,
              public utilsService:UtilsService) { }


  
  ngOnInit(): void {
    this.initializationProcesses()
  }


  initializationProcesses() {
    // Get the file types
    const process =  this.inputParameters['process']
    for(let fileType of GlobalConstant.focusedonFileTypes[process]){
      this.currentFileSelectorType += fileType + ', '
    }
    this.currentFileSelectorType = this.currentFileSelectorType.slice(0, -2);
    
    // Initialize the file list
    this.selectedFilesList = []
    
    // Set the initial value according to the input data
    if(this.inputData){
      if(this.inputData.value){
        this.filesList = this.controllerService.utilsService.deepCopyDict(this.inputData.value)
      }
    }
  }


  getValueC(): any {
    let output: any = {}
    
    // Return cloud files list
    output['filesList'] = this.utilsService.deepCopyDict(this.filesList)

    // Return local files list
    output['selectedFilesList'] = this.selectedFilesList.slice()
    
    return output
  }

  setValueC(input: any) {
    
    /*
    Initially you do not have a list of selected files, you only have the list of files uploaded 
    to the Firebase Database (filesList). When you select one or more new files, there will be 
    two lists of files to be processed differently (fileList and selectedFileList). 
    */

    if (input){
      // Verifi if exist the dictionay -> {'filesList': [], 'selectedFilesList': []}
      if(input['filesList'] && input['selectedFilesList']){
        // Set or update cloud files list
        this.filesList = this.controllerService.utilsService.deepCopyDict(input['filesList'])
        // Set or update local files list
        this.selectedFilesList = input['selectedFilesList'].slice()
      
      } else {
        // Initialize local files list
        this.selectedFilesList = []
        // Set or update cloud files list 
        this.filesList = this.controllerService.utilsService.deepCopyDict(input)
      }
    }
  }
  




  // ----- Methods to manage the file list actions ----- //
  deleteFile(itemIndex: number, location: string){
    if(location == 'cloud'){ // Remove the file from files list
      this.filesList.splice(itemIndex, 1);
    } else if(location == 'local'){ // Remove the file from the selected files list
      this.selectedFilesList.splice(itemIndex, 1);
    }
  }

  downloadFile(itemIndex: number, location: string){
    if(location == 'cloud'){ // Download the file from cloud
      this.utilsService.downloadFile(this.filesList[itemIndex].downloadUrl, this.filesList[itemIndex].name)
    } else if(location == 'local'){ // Download the file from local
      this.utilsService.downloadLocalFile(this.selectedFilesList[itemIndex])
    }
  }


  



  // ----- Methods to manage files selection ----- //
  public onFileSelected(filesArray: any){   
    const process =  this.inputParameters['process']
    // Verify that there is at least one file selected
    if(filesArray.length !== 0){
      // Filter the files according to the file types
      for(let file of filesArray){
        if (GlobalConstant.focusedonFileTypes[process].includes(this.getFileExtension(file))) {
          // Verify that the current file is not already in the existing file list
          const isFileAlreadyAdded_Existing = this.filesList.some(f => f.name === file.name)
          // Verify that the current file is not already in the new file list
          const isFileAlreadyAdded_New = this.selectedFilesList.some(f => f.name === file.name && f.size === file.size && f.type === file.type)
          if (!isFileAlreadyAdded_Existing && !isFileAlreadyAdded_New) {
            // Add the new file
            this.selectedFilesList.push(file);
          }
        }      
      }
    } else {
      return
    }
  }











  // ----- Functions to return the corresponding information to parent class ------ //

  getResultDict(){
    // File names to be kept in firestore  
    let value = this.controllerService.utilsService.deepCopyDict(this.filesList)
    // Files to be uploaded to cloud storate
    let files = this.selectedFilesList //this.controllerService.utilsService.deepCopyDict(this.selectedFilesList)
    // Restult dict
    let resultDict = {
      key: this.inputParameters.key,
      type: this.inputParameters.type,
      process: this.inputParameters.process,
      value: value,                    
      files: files,            
    }
    return resultDict
  }

  getFileExtension(filename: any): string {
    const parts = filename.name.split('.');
    if (parts.length > 1) {
      const ext = parts[parts.length - 1];
      return "." + ext.toLowerCase();
    } else {
      return '';
    }
  }


}
