
<mat-toolbar color="primary">
  <mat-icon *ngIf="controllerService.getPatientId()!==null">person</mat-icon><span>{{controllerService.getPatientId()}}</span>
  <span class="toolbar-spacer"></span>
  <span>{{controllerService.userAuthenticationService.getUserEmail()}}</span>
  <button 
    mat-icon-button (click)="button_logout()"
    matTooltip={{languageService.Strings.landingPageStrings.button_logout}}>
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>

<div class="global-background">
  <button 
    *ngFor="let studyId of studyList"
    mat-raised-button 
    color="primary"
    class="base_buttons"
    type="button"  
    style="width: 100%; max-width: 400px;"
    (click)="selectStudy(studyId)">
      {{ studyInfoDict[studyId].name }}
  </button>
</div>