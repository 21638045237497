import { Component } from '@angular/core';

import { ControllerService } from 'src/app/services/controller/controller.service';
import { LanguageService } from 'src/app/services/language/language.service'
import { UtilsService } from 'src/app/services/utils/utils.service';
import { DicomService } from 'src/app/services/dicom/dicom.service';

import { GlobalConstant } from 'src/app/constants/global-constant';

@Component({
  selector: 'app-full-dicom-field',
  templateUrl: './full-dicom-field.component.html',
  styleUrls: ['./full-dicom-field.component.css'],
  providers: [DicomService] // Provider at component level so there is an instance of dicom service for each component (not shared)
})
export class FullDicomFieldComponent {

  // Input parameters. Pass through the reference when creating the view
  public inputParameters:any = {}
  public inputData:any = {}

  fieldDisabled: boolean;

  dicomFileList:any = null
  
  dicomData:any = null
  replaceOrDeleteExistingBackendFiles:boolean = false

  // Waiting popup info
  displayWaitingSpiner = false
  waitingMessage = ''

  // Progress popup info
  progressIndicator:number = 0
  displayProgressIndicator:boolean = false

  constructor(public controllerService:ControllerService,
    public languageService:LanguageService,
    public utilsService:UtilsService,
    public dicomService:DicomService) {}


  
  ngOnInit(): void {
    this.initializationProcesses()
    if(this.inputData){
      if(this.inputData.value){
        this.dicomData = this.controllerService.utilsService.deepCopyDict(this.inputData.value)
      }
    }
  }


  initializationProcesses() {
    // Clear selection
    this.dicomFileList = []
    // Clear all previous data
    this.dicomService.initializeAllVariables()
    // Reset action variables
    this.replaceOrDeleteExistingBackendFiles = false
  }


  getValueC(): any {
    if(this.dicomService.getNumberOfPatients() > 0){
      // Get the dicom files to be uploaded
      let files = this.dicomService.getAllFiles()
      // File names to be kept in firestore  
      let description = this.getInformationStr()
      // Store the information
      this.dicomData = {'description': description, 'files': files}
      // Reset the view
      this.initializationProcesses()
    }
    // Return the updated information
    return this.dicomData
  }



  setValueC(input: any) {
    if (input) {
      // Set the initial value according to the input data
      this.dicomData = this.controllerService.utilsService.deepCopyDict(input)
      // Reset the view
      this.initializationProcesses()
    }
  }


  getInformationStr(){
    let informationStr = this.languageService.Strings.dicomFieldStrings.information_TotalPatients
    informationStr += this.dicomService.getNumberOfPatients().toString()
    informationStr += ' - ' + this.languageService.Strings.dicomFieldStrings.information_TotalSeries
    informationStr += this.dicomService.getTotalNumberOfSeries().toString()
    informationStr += ' - ' + this.languageService.Strings.dicomFieldStrings.information_TotalImages
    informationStr += this.dicomService.getTotalNumberOfImages().toString()
    return informationStr
  }


  // ----- Methods to manage files selection ----- //
  public async onDirSelected(fileArray:any){   
    // Verify that there is at least one file selected
    if(fileArray.length == 0){
      return
    }
    // Display waiting popup
    this.waitingMessage = this.languageService.Strings.dicomFieldStrings.waitingMessage_ParsingDicom
    this.displayWaitingSpiner = true
    // Read dicom
    await this.dicomService.loadDicomdirStructure(fileArray)
    // Stop the waiting popup
    this.displayWaitingSpiner = false
    // Notify that the backend data should be replaced with the new data if accepted
    this.replaceOrDeleteExistingBackendFiles = true
  }




  async downloadFiles(){
    // Display waiting popup
    this.waitingMessage = this.languageService.Strings.dicomFieldStrings.waitingMessage_DownloadingDicom
    this.displayProgressIndicator = true
    this.progressIndicator = 0 
    // Downaload the files
    let zipDirName = this.languageService.Strings.dicomFieldStrings.dicomZipDirName
    if(this.dicomData.files === GlobalConstant.firestore.key_largeDocumentMovedToStorage){
      // Online download when the firestor document has been store into cloud storage due to a high number of files
      const files = await this.utilsService.downloadJsonFileAndDecodeItToADictionaryFromRAM(this.dicomData.downloadUrl)
      await this.utilsService.downloadFileListAndZip(files, zipDirName, this.updateProgress.bind(this))
    }else if(this.dicomData.files[0]['downloadUrl']){
      // Online standard mode
      await this.utilsService.downloadFileListAndZip(this.dicomData.files, zipDirName, this.updateProgress.bind(this))
    }else{
      // Downloaded from RAM. Files were just loaded 
      await this.utilsService.downloadLocalFileListAndZip(this.dicomData.files, zipDirName)
    }
    // Stop the waiting popup
    this.displayProgressIndicator = false
  }


  deleteBackendFiles(){
    this.dicomData = null
    // Notify that backend files must be deleted
    this.replaceOrDeleteExistingBackendFiles = true
  }

  deleteFrontendFiles(){
    this.dicomData = null
    // Clear the dicomdir object
    this.dicomService.initializeAllVariables()
  }



  getResultDict(){
    // If there are not images selected, return an empty dictionary
    if(this.dicomService.getNumberOfPatients() == 0){
      // Restult dict
      let resultDict = {
        key: this.inputParameters.key,
        type: this.inputParameters.type,            
        value: this.dicomData,
        replace: this.replaceOrDeleteExistingBackendFiles
      }
      return resultDict
    }
    // Get the dicom files to be uploaded
    let files = this.dicomService.getAllFiles()
    // File names to be kept in firestore  
    let description = this.getInformationStr()
    // Restult dict
    let resultDict = {
      key: this.inputParameters.key,
      type: this.inputParameters.type, 
      description: description,                   
      files: files,            
      value: null,
      replace: this.replaceOrDeleteExistingBackendFiles
    }
    return resultDict
  }


  async updateProgress(progressDict){
    const completed = progressDict['completed']
    const total = progressDict['total'] 
    this.progressIndicator = Math.ceil(100 * completed / total)
  }


}
