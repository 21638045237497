
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Custom services 
import { UtilsService } from 'src/app/services/utils/utils.service';
import { LanguageService } from 'src/app/services/language/language.service';


/* Specify the structure of the dictionary that will receive the dialog when being openned */
export interface DialogData {informationTitle:string, informationMessage:string}


@Component({
  selector: 'app-information-popup',
  templateUrl: './information-popup.component.html',
  styleUrls: ['./information-popup.component.css']
})
export class InformationPopupComponent {


  public informationTitle:string='';
  public informationMessage:string='';


  constructor(public utilsService:UtilsService,
              public languageService:LanguageService,
              public dialogRef: MatDialogRef<InformationPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData)
  {
    this.informationTitle = data.informationTitle
    this.informationMessage = data.informationMessage
  }


  ngOnInit(): void {
    // For now, empty
  }


  // ----- Button actions ----- //

  acceptButtonClicked(){
    this.dialogRef.close({})
  }


}
