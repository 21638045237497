
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';



import { NONE_TYPE } from '@angular/compiler';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';



// Custom services
import { UtilsService } from '../utils/utils.service';
import { LanguageService } from '../language/language.service';
import { FirestoreService } from './firestore.service';

// Constants
import { GlobalConstant } from 'src/app/constants/global-constant';




@Injectable({
  providedIn: 'root'
})
export class UserAuthenticationService {

  // Observable that indicates if the user is logged in or not to other services or components
  private userIsLoggedBehaviorSubject: BehaviorSubject<boolean>;

  // Custom variables to control the user state
  private _currentUser:any = null;
  private _authorizedUserDict:any = null

  constructor(public auth: AngularFireAuth,
              public utilsService:UtilsService,
              public languageService:LanguageService,
              public firestoreService:FirestoreService,
              ) 
  {
    // Observable that indicates if the user is logged in or not to other services or components
    this.userIsLoggedBehaviorSubject = new BehaviorSubject<boolean>(false);
  }


  // ------ Methods to access privat evariables ------ //

  // User 
  async getUserTokenId() {
    const user = await this.auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken();
      return idToken;
    }
    return '';
  }

  // User Id
  getUserId(){
    return this._currentUser.uid
  }

  getAuthorizedUserDict(){
    return this._authorizedUserDict
  }

  getUserEmail(){
    return this._currentUser.email
  }


  async login(provider:string){
    try{
      if(provider === 'google'){
        let result = await this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());  
        // Store the current user information
        this._currentUser = result.user      
      }else if(provider === 'microsoft'){
        let result = await this.auth.signInWithPopup(new firebase.auth.OAuthProvider('microsoft.com'));  
        // Store the current user information
        this._currentUser = result.user  
      }
      // Get the user dictionary
      const collection = GlobalConstant.firestore.collection_authorizedUsers
      const document = this.getUserEmail()
      this._authorizedUserDict = (await this.firestoreService.getDocument(collection, document)).data()
      // Set user is logged to true
      this.setUserIsLoggedValue(true)
      // Return ok to close the login popup
      return {resultOk:true}
    }catch(error) {
      // Other error codes:
      //  'auth/multi-factor-auth-required'
      //  'auth/user-not-found'
      //  'auth/wrong-password'
      //  ...
      return {resultOk:false, error:error}
    }
  }





  // Logout process
  logout(){
    this.auth.signOut()
    this._currentUser = NONE_TYPE
    this._authorizedUserDict = NONE_TYPE
    this.setUserIsLoggedValue(false)
  }





  


  // ------ User is logged observable ----//

  // Methods for updating the observable that indicates if the user is logged in or not to other services or components
  setUserIsLoggedValue(newValue:boolean): void {
    this.userIsLoggedBehaviorSubject.next(newValue);
  }
  getUserIsLoggedValue(): Observable<boolean> {
    return this.userIsLoggedBehaviorSubject.asObservable();
  }




  

  

  










}
