import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef, Input, Output, EventEmitter, ComponentRef } from '@angular/core';




// Form control
import { FormGroup, FormBuilder } from '@angular/forms';
import { ItemDirective } from '../../../directives/item.directive'

//  Available field generators components
import { TextFieldComponent } from '../specific-fields/text-field/text-field.component';
import { NumericFieldComponent } from '../specific-fields/numeric-field/numeric-field.component';
import { BooleanFieldComponent } from '../specific-fields/boolean-field/boolean-field.component';
import { CategoricalFieldComponent } from '../specific-fields/categorical-field/categorical-field.component';
import { DateFieldComponent } from '../specific-fields/date-field/date-field.component';
import { FileFieldComponent } from '../specific-fields/file-field/file-field.component';
import { DicomFieldComponent } from '../specific-fields/dicom-field/dicom-field.component';
import { FullDicomFieldComponent } from '../specific-fields/full-dicom-field/full-dicom-field.component';
import { ControllerService } from 'src/app/services/controller/controller.service';
import { LanguageService } from 'src/app/services/language/language.service'

@Component({
  selector: 'app-global-form',
  templateUrl: './global-form.component.html',
  styleUrls: ['./global-form.component.css']
})
export class GlobalFormComponent implements OnInit {


  @Input() mode: string = ''     // visualize | submit
  @Input() fieldList: any = []   // list with fields dictionary
  @Input() inputFormData: any = {}   // Dictionary with each field key and its information

  @Output() submitEvent = new EventEmitter<any>();


  // Form data dictionray
  formCurrentMode: string = 'input'  // TODO: Manage it automatically ---------------------------------------------------------------
  formData: any = [];
  formControlGroup_Global: FormGroup = new FormGroup({})
  formControlGroup_GlobalList: any = []
  studyDataTemp: { [key: string]: any } = {};


  // Manage children
  // Manage children
  //  Reference to the child layout where the items (children) are going to be load
  @ViewChild(ItemDirective, { static: true }) itemhost!: ItemDirective;
  //  List for storing the children views to access them from the TS
  public viewChildren: any = []
  public viewChildrenTypes: any = []

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private formBuilder: FormBuilder,
    private controllerService: ControllerService,
    public languageService: LanguageService) { }


  ngOnInit(): void {
    this.initializeChildForm();
    this.setFormCurrentMode(this.controllerService.getFormLocked());
    this.initializeStudyDataTemp();
  }




  ngAfterContentChecked(): void {
    // Prepare the form group list
    this.formControlGroup_GlobalList = []
    for (let viewItem of this.viewChildren) {
      this.formControlGroup_GlobalList.push(viewItem.instance.formControlGroup)
    }
    this.formControlGroup_Global = this.formBuilder.group({
      items: this.formBuilder.array(this.formControlGroup_GlobalList)
    }
    )
    // Set corresponding form visibility according to its state (read only or not)
    //this.updateFormVisibility()
  }




  initializeChildForm() {
    // Add the view and get its corresponding form groups
    let viewContainerRef = this.itemhost.viewContainerRef;
    // Remove the existing components
    if (this.viewChildren.length > 0) {
      this.clearChildComponent(this.viewChildren[0])
      this.viewChildren = []
    }
    // Load the new components one by one
    for (let fieldDict of this.fieldList) {
      // Load the new component
      if (fieldDict.type === 'textField') {
        let component = TextFieldComponent
        let newItem: ComponentRef<TextFieldComponent> = viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(component))
        newItem.instance.inputParameters = fieldDict
        newItem.instance.inputData = this.inputFormData[fieldDict.key]
        this.viewChildren.push(newItem)
      } else if (fieldDict.type === 'numericField') {
        let component = NumericFieldComponent
        let newItem: ComponentRef<NumericFieldComponent> = viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(component))
        newItem.instance.inputParameters = fieldDict
        newItem.instance.inputData = this.inputFormData[fieldDict.key]
        this.viewChildren.push(newItem)
      } else if (fieldDict.type === 'booleanField') {
        let component = BooleanFieldComponent
        let newItem: ComponentRef<BooleanFieldComponent> = viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(component))
        newItem.instance.inputParameters = fieldDict
        newItem.instance.inputData = this.inputFormData[fieldDict.key]
        this.viewChildren.push(newItem)
      } else if (fieldDict.type === 'categoricalField') {
        let component = CategoricalFieldComponent
        let newItem: ComponentRef<CategoricalFieldComponent> = viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(component))
        newItem.instance.inputParameters = fieldDict
        newItem.instance.inputData = this.inputFormData[fieldDict.key]
        this.viewChildren.push(newItem)
      } else if (fieldDict.type === 'dateField') {
        let component = DateFieldComponent
        let newItem: ComponentRef<DateFieldComponent> = viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(component))
        newItem.instance.inputParameters = fieldDict
        newItem.instance.inputData = this.inputFormData[fieldDict.key]
        this.viewChildren.push(newItem)
      } else if (fieldDict.type === 'fileField') {
        let component = FileFieldComponent
        let newItem: ComponentRef<FileFieldComponent> = viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(component))
        newItem.instance.inputParameters = fieldDict
        newItem.instance.inputData = this.inputFormData[fieldDict.key]
        this.viewChildren.push(newItem)
      } else if (fieldDict.type === 'dicomField') {
        let component = DicomFieldComponent
        let newItem: ComponentRef<DicomFieldComponent> = viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(component))
        newItem.instance.inputParameters = fieldDict
        newItem.instance.inputData = this.inputFormData[fieldDict.key]
        this.viewChildren.push(newItem)
      } else if (fieldDict.type === 'fullDicomField') {
        let component = FullDicomFieldComponent
        let newItem: ComponentRef<FullDicomFieldComponent> = viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(component))
        newItem.instance.inputParameters = fieldDict
        newItem.instance.inputData = this.inputFormData[fieldDict.key]
        this.viewChildren.push(newItem)
      }

      
      


    }
  }
  clearComponent(viewContainerRef: ViewContainerRef) {
    viewContainerRef.clear();
  }
  clearChildComponent(component: any) {
    component.hostView.destroy()
  }
  setFormCurrentMode(formLocked: boolean) {
    if (formLocked) {
      this.formCurrentMode = "edit";
      this.fieldListDisable();
    } else {
      this.formCurrentMode = "input";
      this.fieldListEnable();
    }
  }


  // ---------- Form actions ---------- //

  formButtonClicked(buttonAction: string) {
    if (buttonAction === 'submit') {
      this.submit()
      if (this.formCurrentMode = "editing") {
        this.formCurrentMode = "edit";
        this.updateStudyDataTemp();
        this.fieldListDisable();
      }
    } else if (buttonAction === 'edit') {
      this.formCurrentMode = "editing";
      this.fieldListEnable();
    } else if (buttonAction === 'cancel') {
      this.formCurrentMode = "edit";
      this.loadStudyDataTemp();
      this.fieldListDisable();
    }
  }




  // The form fields set as primary keys cannot be modified and so, they are not integrated in the formControlGroup.
  // However, they are integrated in the child instance field lists and they need to be parsed and store in the data base.  
  submit() {
    // Store the information of each item in a list
    this.formData = {}
    for (let index = 0; index < this.fieldList.length; index++) {
      // Get the item result dict
      let itemResultDict = this.viewChildren[index].instance.getResultDict()
      this.formData[itemResultDict.key] = itemResultDict
    }
    // Return the information to the parent component     
    this.submitEvent.next(this.formData);
  }


  fieldListDisable() {
    for (let index = 0; index < this.fieldList.length; index++) {
      if (this.fieldList[index].type === 'textField') {
        this.viewChildren[index].instance.textFormControl.disable()
      } else if (this.fieldList[index].type === 'numericField') {
        this.viewChildren[index].instance.numericFormControl.disable()
      } else if (this.fieldList[index].type === 'booleanField') {
        this.viewChildren[index].instance.booleanFormControl.disable()
      } else if (this.fieldList[index].type === 'categoricalField') {
        this.viewChildren[index].instance.fieldDisabled = true;
      } else if (this.fieldList[index].type === 'dateField') {
        this.viewChildren[index].instance.dateFormControl.disable()
      } else if (this.fieldList[index].type === 'fileField'){ 
        this.viewChildren[index].instance.fieldDisabled = true;
      } else if (this.fieldList[index].type === 'dicomField'){ 
        this.viewChildren[index].instance.fieldDisabled = true;
      }else if (this.fieldList[index].type === 'fullDicomField'){ 
        this.viewChildren[index].instance.fieldDisabled = true;
      }
    }
  }

  fieldListEnable() {
    for (let index = 0; index < this.fieldList.length; index++) {
      if (this.fieldList[index].type === 'textField') {
        this.viewChildren[index].instance.textFormControl.enable()
      } else if (this.fieldList[index].type === 'numericField') {
        this.viewChildren[index].instance.numericFormControl.enable()
      } else if (this.fieldList[index].type === 'booleanField') {
        this.viewChildren[index].instance.booleanFormControl.enable()
      } else if (this.fieldList[index].type === 'categoricalField') {
        this.viewChildren[index].instance.fieldDisabled = false;
      } else if (this.fieldList[index].type === 'dateField') {
        this.viewChildren[index].instance.dateFormControl.enable()
      } else if (this.fieldList[index].type === 'fileField'){ 
        this.viewChildren[index].instance.fieldDisabled = false;
      } else if (this.fieldList[index].type === 'dicomField'){ 
        this.viewChildren[index].instance.fieldDisabled = false;
      } else if (this.fieldList[index].type === 'fullDicomField'){ 
        this.viewChildren[index].instance.fieldDisabled = false;
      }
    }
  }

  updateStudyDataTemp() {
    for (let index = 0; index < this.fieldList.length; index++) {
      if (this.fieldList[index].type === 'textField') {
        this.studyDataTemp[this.fieldList[index].key] = this.controllerService.utilsService.deepCopyDict(this.viewChildren[index].instance.textFormControl.value);
      } else if (this.fieldList[index].type === 'numericField') {
        this.studyDataTemp[this.fieldList[index].key] = this.controllerService.utilsService.deepCopyDict(this.viewChildren[index].instance.numericFormControl.value);
      } else if (this.fieldList[index].type === 'booleanField') {
        this.studyDataTemp[this.fieldList[index].key] = this.controllerService.utilsService.deepCopyDict(this.viewChildren[index].instance.booleanFormControl.value);
      } else if (this.fieldList[index].type === 'categoricalField') {
        this.studyDataTemp[this.fieldList[index].key] = this.controllerService.utilsService.deepCopyDict(this.viewChildren[index].instance.getValueC());
      } else if (this.fieldList[index].type === 'dateField') {
        if (this.viewChildren[index].instance.dateFormControl.value !== null) {
          this.studyDataTemp[this.fieldList[index].key] = new Date(this.viewChildren[index].instance.dateFormControl.value);
        }
      } else if (this.fieldList[index].type === 'fileField'){
        this.studyDataTemp[this.fieldList[index].key] = this.viewChildren[index].instance.getValueC();
      } else if (this.fieldList[index].type === 'dicomField'){
        this.studyDataTemp[this.fieldList[index].key] = this.controllerService.utilsService.deepCopyDict(this.viewChildren[index].instance.getValueC());
      } else if (this.fieldList[index].type === 'fullDicomField'){
        this.studyDataTemp[this.fieldList[index].key] = this.controllerService.utilsService.deepCopyDict(this.viewChildren[index].instance.getValueC());
      }
      
    }
  }

  loadStudyDataTemp() {
    for (let index = 0; index < this.fieldList.length; index++) {
      if (this.fieldList[index].type === 'textField') {
        this.viewChildren[index].instance.textFormControl.setValue(this.studyDataTemp[this.fieldList[index].key])
      } else if (this.fieldList[index].type === 'numericField') {
        this.viewChildren[index].instance.numericFormControl.setValue(this.studyDataTemp[this.fieldList[index].key])
      } else if (this.fieldList[index].type === 'booleanField') {
        this.viewChildren[index].instance.booleanFormControl.setValue(this.studyDataTemp[this.fieldList[index].key])
      } else if (this.fieldList[index].type === 'categoricalField') {
        this.viewChildren[index].instance.setValueC(this.studyDataTemp[this.fieldList[index].key])
      } else if (this.fieldList[index].type === 'dateField') {
        this.viewChildren[index].instance.dateFormControl.setValue(this.dateConvertion(this.studyDataTemp[this.fieldList[index].key]))
      } else if (this.fieldList[index].type === 'fileField'){
        this.viewChildren[index].instance.setValueC(this.studyDataTemp[this.fieldList[index].key])
      } else if (this.fieldList[index].type === 'dicomField'){
        this.viewChildren[index].instance.setValueC(this.studyDataTemp[this.fieldList[index].key])
      } else if (this.fieldList[index].type === 'fullDicomField'){
        this.viewChildren[index].instance.setValueC(this.studyDataTemp[this.fieldList[index].key])
      }
    }
  }

  initializeStudyDataTemp() {
    for (let index = 0; index < this.fieldList.length; index++) {
      if (this.inputFormData[this.fieldList[index].key] !== undefined) {
        this.studyDataTemp[this.fieldList[index].key] = this.controllerService.utilsService.deepCopyDict(this.inputFormData[this.fieldList[index].key].value);
      }
    }
  }

  dateConvertion(dateValue:any): Date {
    if (dateValue !== undefined){
      if (isNaN(Number(dateValue))) {
        return new Date(dateValue.seconds * 1000);
      } else {
        return dateValue;
      }
    } else {
      return undefined;
    }
  }
}
