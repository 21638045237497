import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


// Custom services 
import { UtilsService } from '../../../services/utils/utils.service';
import { LanguageService } from '../../../services/language/language.service';


/* Specify the structure of the dictionary that will receive the dialog when being openned */
export interface DialogData {popupTitle:string, popupText:string}


@Component({
  selector: 'app-decision-popup',
  templateUrl: './decision-popup.component.html',
  styleUrls: ['./decision-popup.component.css']
})
export class DecisionPopupComponent {

  public popupTitle:string='';
  public popupText:string='';

  constructor(public utilsService:UtilsService,
              public languageService:LanguageService,
              public dialogRef: MatDialogRef<DecisionPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData)
  {
    this.popupTitle = data.popupTitle
    this.popupText = data.popupText
  }



  // ----- Button actions ----- //

  cancelButtonClicked(){
    this.dialogRef.close(false)
  }


  acceptButtonClicked(){
    this.dialogRef.close(true)
  }

}
