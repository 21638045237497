import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';



@Component({
  selector: 'app-global-study',
  templateUrl: './global-study.component.html',
  styleUrls: ['./global-study.component.css']
})
export class GlobalStudyComponent implements OnInit {


  @Input() mode:string = ''     // visualize | submit
  @Input() tabList:any = []     // tabs containing panels containing fields
  @Input() studyData:any = {}   // Dictionary containg the information {tabKey: {panelKey: panelData}}
 
  @Output() submitEvent = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
    // For now, empty
  }
    


  // ----- Button actions ------ //









  submitEvent_formDataSubmitted(panelData:any, tabIndex:number, panelIndex:number){
    // Generate the update dictionary
    let tabKey = this.tabList[tabIndex].key
    let panelKey = this.tabList[tabIndex].panelList[panelIndex].key
    let resultDict:any = {tabKey:tabKey, panelKey:panelKey, panelData:panelData}
    // Submit the updatableDict to the parent component
    this.submitEvent.next(resultDict);
  }



  getSinglePanelData(tabIndex:any, panelIndex:any){
    // Get the data corresponding to the target pannel and return it
    let tabKey = this.tabList[tabIndex].key
    let panelKey = this.tabList[tabIndex].panelList[panelIndex].key

    if(this.studyData[tabKey]){
      if(this.studyData[tabKey][panelKey]){
        return this.studyData[tabKey][panelKey]
      }
    }
    // Return an empty dictionary if there is not data available
    return {}
  }



}
