import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';



@Component({
  selector: 'app-categorical-field',
  templateUrl: './categorical-field.component.html',
  styleUrls: ['./categorical-field.component.css']
})
export class CategoricalFieldComponent implements OnInit {



  // Input parameters. Pass through the reference when creating the view
  public inputParameters: any = {}
  public inputData: any = {}

  // Array for controlling which category is selected
  public categorySelection: any;

  // Form data dictionray
  public formData: any;
  public formControlGroup: any;


  public formBuilder: any;

  public resultValue = [];

  categoricalFormControl_RadioButton = new FormControl('', []);

  categoricalFormControl_CheckBox = null

  categoricalFormControl: any;

  fieldDisabled: boolean;


  constructor(formBuilder: FormBuilder) {
    this.formBuilder = formBuilder
  }


  ngOnInit(): void {
    this.initializationProcesses()
  }



  initializationProcesses() {
    // Create the category selection array and initialize it
    this.categorySelection = new Array(this.inputParameters.categories.length).fill(false);
    // Set the input value according to the input data if possible
    if (this.inputData) {
      if (this.inputData.value) {
        if (this.inputData.value.length == this.inputParameters.categories.length) {
          this.categorySelection = this.inputData.value
        }
      }
    }
    // -- Initialization process for single select mode  -- //
    if (!this.inputParameters.multiselect) {
      // Set the corresponding form control group
      this.categoricalFormControl = new FormControl('', []);
      this.formControlGroup = new FormGroup({
        value: this.categoricalFormControl,
      })
      // Set default values into the form control
      this.categoricalFormControl.setValue(null)
      for (let index = 0; index < this.categorySelection.length; index++) {
        if (this.categorySelection[index]) {
          this.categoricalFormControl.setValue(index)
          break
        }
      }
    }
    // -- Initialization process for multi select mode  -- //
    if (this.inputParameters.multiselect) {
      // Set the corresponding form control group
      this.categoricalFormControl = this.formBuilder.array([], null)
      this.formControlGroup = new FormGroup({
        value: this.categoricalFormControl,
      })

      // Get the current categories as a form array (the selected ones, stored in the form control group)
      const categories = <FormArray>this.formControlGroup.get('value') as FormArray;
      // Add or remove the selected category to the form group controls
      for (let value of this.categorySelection) {
        if (value) {
          categories.push(new FormControl(value))
        }
      }
    }
  }

  getValueC(): any {
    if (!this.inputParameters.multiselect) {
      let value = new Array(this.inputParameters.categories.length).fill(false);
      if (this.categoricalFormControl.value != null) {
        value[this.categoricalFormControl.value] = true;
      }
      return value;
    } else {
      return this.categorySelection;
    }
  }

  setValueC(input: any) {
    if (input){ 
      if (!this.inputParameters.multiselect) {
        for (let index = 0; index < input.length; index++) {
          if(input[index]){
            this.categoricalFormControl.setValue(index)
            break;
          }
        }
      } else {
        for (let index = 0; index < input.length; index++) {
          this.categorySelection[index] = input[index];
        }
      }
    }
  }

  // ----------- Radio button ----------- //
  radioButtonClicked(event: any, currentButton: any) {
    // Remove the default behaviour to allow unselect
    // event.preventDefault()
    // Get previous and new values
    let currentValue = this.categoricalFormControl.value
    let newValue = currentButton.value
    // Remove the existing value
    this.categoricalFormControl.setValue(null)
    currentButton.checked = false
    if (currentValue != null) {
      this.categorySelection[currentValue] = false
    }
    // Add the new one
    if (currentValue != newValue) {
      this.categoricalFormControl.setValue(currentButton.value)
      this.categorySelection[newValue] = true
      currentButton.checked = true
    }
  }


  // ----------- Check box ----------- //
  // Check box on click handle
  handleChangeCheckBox(event: any, clickedElementIndex: number) {
    // Get the current categories as a form array (the selected ones, stored in the form control group)
    const categories = <FormArray>this.formControlGroup.get('value') as FormArray;
    // Add or remove the selected category to the form group controls
    if (event.checked) {
      categories.push(new FormControl(event.source.value))
      this.categorySelection[clickedElementIndex] = true
    } else {
      const index = categories.controls.findIndex(x => x.value === event.source.value);
      categories.removeAt(index);
      this.categorySelection[clickedElementIndex] = false
    }
  }

























  resetToDefaultValue() {
    // Reset the form control
    //  this.formControl.reset()
    this.initializationProcesses()
  }



























  // ----- Functions to return the corresponding information to parent class ------ //

  getResultDict() {
    let resultDict = {
      key: this.inputParameters.key,
      type: this.inputParameters.type,
      value: this.categorySelection
    }
    return resultDict
  }

}
