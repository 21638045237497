

<!-- Tabs -->
<mat-tab-group #matTabs mat-align-tabs="center">
    <mat-tab *ngFor="let tabItem of tabList; let tabIndex=index"
        label={{tabItem.name}}
        >

        <!-- panel -->
        <div class="accordion-div">

            <mat-accordion>
                <mat-expansion-panel *ngFor="let panelItem of tabList[tabIndex].panelList; let panelIndex=index">

                    <mat-expansion-panel-header>
                        <mat-panel-title>{{panelItem.name}}</mat-panel-title>
                        <mat-panel-description></mat-panel-description> <!-- Panel description -->
                    </mat-expansion-panel-header>
                        
                    <!-- Fields area -->
                    <div class="fields-div">

                        <!-- Field List -->
                        <div class=global-list-div *ngIf="panelItem.fieldList.length > 0">

                            <app-global-form
                                [mode]="'visualize'"
                                [fieldList]=panelItem.fieldList 
                                [inputFormData]="getSinglePanelData(tabIndex, panelIndex)"
                                (submitEvent)="submitEvent_formDataSubmitted($event, tabIndex, panelIndex)">       
                            </app-global-form>

                        </div>

                    </div>
                    
                </mat-expansion-panel>
           
            </mat-accordion>

        </div>
        
        

    </mat-tab>
</mat-tab-group>
