import { Component } from '@angular/core';

// services 
import { ControllerService } from 'src/app/services/controller/controller.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-patient-searching-page',
  templateUrl: './patient-searching-page.component.html',
  styleUrls: ['./patient-searching-page.component.css']
})
export class PatientSearchingPageComponent {

  constructor(public controllerService: ControllerService,
    public utilsService: UtilsService,
    public languageService: LanguageService) {}


  // ----- Button actions ----- //

  button_logout() {
    this.controllerService.setPatientId(null);
    this.controllerService.logout()
  }

  button_back(){
    this.controllerService.setCurrentAction('landingPage')
  }

}
