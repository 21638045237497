
<div class="global-container">

    <!-- Accept button -->  
    <button 
        mat-raised-button 
        color="primary"
        class="base_buttons"
        type="button" 
        style="width: 100%;"
        (click)="loginButtonclicked('google')">
            {{languageService.Strings.loginPageStrings.button_login_google}}
    </button>
    
    <button 
        mat-raised-button 
        color="primary"
        class="base_buttons"
        type="button" 
        style="width: 100%;"
        (click)="loginButtonclicked('microsoft')">
            {{languageService.Strings.loginPageStrings.button_login_microsoft}}
    </button>

</div>
