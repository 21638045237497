import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[itemhost]'
})
export class ItemDirective {

  [x: string]: any;
  
  constructor(public viewContainerRef: ViewContainerRef) { }

}

