import { Injectable } from '@angular/core';

// Services 
import { FirestoreService } from '../firestore/firestore.service';
import { FirestorageService } from '../firestore/firestorage.service';
import { DicomService } from '../dicom/dicom.service';

// Constant global
import { GlobalConstant } from 'src/app/constants/global-constant';


@Injectable({
  providedIn: 'root'
})
export class BackendDataService {

  constructor(public firestoreService:FirestoreService,
              public firestorageService:FirestorageService,
              public dicomService:DicomService) { }


  generateFirestoreRoute(studyId, taregetCollection){
    return studyId + '/' + studyId + '/' + taregetCollection
  }


  async getExcelInformation(studyId:string){
    const collection = this.generateFirestoreRoute(studyId, GlobalConstant.firestore.collection_excelInfo)
    const document = GlobalConstant.firestore.document_excelInfo
    let documentDict = (await this.firestoreService.getDocument(collection, document)).data()
    return documentDict
  }



  async getPatientIdList(studyId:string){
    let collection:string = this.generateFirestoreRoute(studyId, GlobalConstant.firestore.collection_patientId)  
    let patientIdDocumentList = await this.firestoreService.getDocumentListFromCollection(collection)
    let patientIdList:any = []
    if(!(patientIdDocumentList === undefined)){
      // Crear lista de pacientes
      for (let document of patientIdDocumentList){
        patientIdList.push(document.id)
      }
      // Ordenar la lista por tamaño del id del paciente
      patientIdList.sort((a, b) => a.length - b.length);
    }
    return patientIdList
  }


  async deletePatient(studyId:string, patientId:string){
    try{
      // Generate an array for promises
      let promiseList:any = []
      // Delete files from storage
      const storagePath = studyId + '/' + patientId
      promiseList.push(this.firestorageService.deleteFolderUsingFirestoragePath(storagePath)) // TODO: TBD -- Improve this method
      // Delete the patient information
      let collection:string = this.generateFirestoreRoute(studyId, GlobalConstant.firestore.collection_stableInfo)  
      let document:string = patientId
      promiseList.push(this.firestoreService.deleteDocument(collection, document))
      // Delete the patient fron the id list
      collection = this.generateFirestoreRoute(studyId, GlobalConstant.firestore.collection_patientId)   
      document = patientId
      promiseList.push(this.firestoreService.deleteDocument(collection, document))
      // Add a "delete order" in the the updatable dictionary for the SQL synchronization
      collection = this.generateFirestoreRoute(studyId, GlobalConstant.firestore.collection_updatedInfo)    
      document = patientId + '___delete'
      let documentDict = {delete: true, timestamp:this.firestoreService.getFirebaseTimestamp()}
      promiseList.push(this.firestoreService.addDocument(collection, document, documentDict, false))
      // Wait for all promises 
      const promiseListResults = await Promise.all(promiseList)
      return {resultOk:true, data:promiseListResults}
    }catch (error) {
      return {resultOk:false, data:error}
    }
  }


  async verifyPatientExists(studyId:string, patientId:string){
    let collection:string = this.generateFirestoreRoute(studyId, GlobalConstant.firestore.collection_patientId)    
    let document:string = patientId
    let patientIdFound = (await this.firestoreService.getDocument(collection, document)).get('patientId')
    // If the patient does not exists in data base, return false
    if(!patientIdFound) return false
    return true
  }

  async addNewPatient(studyId:string, patientId:string){
    // Follow on the process
    let promisesList = []
    // Add patient id to target db list
    let collection:string = this.generateFirestoreRoute(studyId, GlobalConstant.firestore.collection_patientId) 
    let document:string = patientId
    let documentDict:any = {patientId:patientId, 'timestamp':this.firestoreService.getFirebaseTimestamp()}
    let patientId_promise = this.firestoreService.addDocument(collection, document, documentDict)
    promisesList.push(patientId_promise)
    // Add an empty document to the target location
    collection = this.generateFirestoreRoute(studyId, GlobalConstant.firestore.collection_stableInfo) 
    document = patientId
    documentDict = {}
    let stableInfo_promise = this.firestoreService.addDocument(collection, document, documentDict)
    promisesList.push(stableInfo_promise)
    // Await promises
    return Promise.all(promisesList).then(() => {
      return {resultOk:true}
    }).catch((error) => {
      return {resultOk:false, error:error}
    })
  }

  async findPatient(studyId:string, patientId:string){
    let collection:string = this.generateFirestoreRoute(studyId, GlobalConstant.firestore.collection_stableInfo) 
    let document:string = patientId
    let documentDict:any = (await this.firestoreService.getDocument(collection, document)).data()
    return documentDict
  }

  // Upload files to cloud storage and store the download url in the dictionary object for firestore
  async updatePatientInformation(studyId:string, patientId:string, tabId:string, panelId:string, panelDataDict:any, 
    targetCallbackMethod: (data: any) => void
  ){
    // Parse fields of type "fileField" or "dicomField" to extract the files from the dictionary.
    // Files need to be uploaded separately to firebase cloud storage 
    for(let fieldKey in panelDataDict){
      let fieldDict = panelDataDict[fieldKey]
      if(fieldDict.type === 'fileField' || fieldDict.type === 'focusedonField'){ 
        // Files to be uploaded or removed from storage
        let storagePath = studyId + '/' + patientId + '/' + tabId + '/' + panelId + '/' + fieldDict.key
        let fileNameList:any = [] // Files to be kept (not removed from storage)
        try{
          // Add each file one by one to the "value" list {name:xx, downloadUrl:xx}
          for(let file of fieldDict.files){
            let downloadUrl = await this.firestorageService.uploadFileSync(file, storagePath+'/'+file.name)
            fieldDict.value.push({'name':file.name, 'downloadUrl':downloadUrl})
          }
          // Store the files that need to be kept
          for(let fileDict of fieldDict.value){
            fileNameList.push(fileDict['name'])
          }
          // Remove the files within the storage path that are not in the list
          await this.firestorageService.deleteFilesThatAreNotInList(storagePath, fileNameList)
        }catch (error){
          return {resultOk:false, error:error}
        }
        delete fieldDict.files
      } else if(fieldDict.type === 'dicomField' || fieldDict.type === 'fullDicomField' || fieldDict.type === 'dicomStudyField' ){
        // File storage path
        let storagePath = studyId + '/' + patientId + '/' + tabId + '/' + panelId + '/' + fieldDict.key
        let storagePath_largeDocument = studyId + '/' + patientId + '/' + GlobalConstant.firestore.key_largeDocumentMovedToStorage + '/'+ tabId + '/' + panelId + '/' + fieldDict.key
        // DICOM files to be replaced
        if(fieldDict.replace === true){
          await this.firestorageService.deleteFolderUsingFirestoragePath(storagePath)
          await this.firestorageService.deleteFolderUsingFirestoragePath(storagePath_largeDocument)
          delete fieldDict.replace
        }
        // DICOM files to be uploaded (replace existing ones)
        if(fieldDict.files && fieldDict.description){
          try{
            // Anonymize and add each file one by one to the "value" list {name:xx, downloadUrl:xx}
            /*
            let dicomFilePromises = fieldDict.files.map(file => 
              this.anonymizeAndUploadFile(file, storagePath, patientId)
            );
            let uploadedFiles = await Promise.all(dicomFilePromises);
            */

            // Anonymize and add each file one by one to the "value" list {name:xx, downloadUrl:xx}
            let uploadedFiles = await this.limitConcurrency(
              fieldDict.files,
              GlobalConstant.fileUploadingConcurrency,
              file => this.anonymizeAndUploadFile(file, storagePath, patientId),
              targetCallbackMethod
            )

            // Filter valid files (avoid erros during anonymizing and uploading)
            uploadedFiles = uploadedFiles.filter(fileInfo => fileInfo !== null);
            fieldDict.value = {'description':fieldDict.description, 'files':uploadedFiles}
            // If the number of files is higher than the limit, split it into multiple firestore documents
            if(uploadedFiles.length > GlobalConstant.firestore.limit_numberOfDicomFilesInMainDict){
              // TODO: TBD -- Upload the file list as an json file to storage.
              const downloadUrl = await this.moveFilesInLargeDocumentToStorage(uploadedFiles, studyId, patientId, tabId, panelId, fieldDict.key)
              // Modify the fieldDict value to notify the frontend
              fieldDict.value = {'description':fieldDict.description, 'files':GlobalConstant.firestore.key_largeDocumentMovedToStorage, 'downloadUrl':downloadUrl}
            }
          }catch (error){
            console.log('error: ', error)
            return {resultOk:false, error:error}
          }
        }
        // Delete fields that should not be in firestore (json)
        delete fieldDict.files
        delete fieldDict.description
      } 
    } 
    // Upload dictionary to cloud storage
    let promisesList = []
    // Update the stable dictionary
    let collection:string = this.generateFirestoreRoute(studyId, GlobalConstant.firestore.collection_stableInfo) 
    let document:string = patientId
    let documentDict:any = {[tabId]: {[panelId]: panelDataDict}}
    let stableInfo_promise = this.firestoreService.addDocument(collection, document, documentDict, true)
    promisesList.push(stableInfo_promise)
    // Update the dictionary to the updatable or synchronizatoin collection 
    document = patientId + '___' + tabId + '___' + panelId
    documentDict = this.getPanelData(panelDataDict);
    //    Add timestamp to the panel dict
    documentDict['timestamp'] = this.firestoreService.getFirebaseTimestamp()
    //    Check if it is a synchronization panel
    let synchronizationPanel = this.evaluateIfItIsASynchronizationPanel(studyId, tabId, panelId) 
    if(synchronizationPanel){
      // Update the same dictionary to the synchronization collection if needed
      collection = this.generateFirestoreRoute(studyId, GlobalConstant.firestore.collection_synchronizationInfo) 
      let synchronizationInfo_promise = this.firestoreService.addDocument(collection, document, documentDict, false)
      promisesList.push(synchronizationInfo_promise)
    }else{
      //    Upload the dictionary to the update colection
      collection = this.generateFirestoreRoute(studyId, GlobalConstant.firestore.collection_updatedInfo) 
      let updatableInfo_promise = this.firestoreService.addDocument(collection, document, documentDict, false)
      promisesList.push(updatableInfo_promise)
    }
    // Await promises
    return Promise.all(promisesList).then(() => {
      return {resultOk:true}
    }).catch((error) => {
      return {resultOk:false, error:error}
    })
  }



  async limitConcurrency(files, limit, targetConcurrentFunction, targetCallbackMethod: (data: any) => void) {
    const results = [];
    const executing = [];
    let completed = 0;
    let total = files.length
    for (const file of files) {
      const p = targetConcurrentFunction(file).then(result => {
        // Remove finished from executing array
        executing.splice(executing.indexOf(p), 1);
        // Count the progress
        completed++;
        // Return the progress
        targetCallbackMethod({completed:completed, total:total})
        return result;
      });
      results.push(p);
      executing.push(p);
      if (executing.length >= limit) {
        // Wait for any of the promises to finish
        await Promise.race(executing);
      }
    }
    return Promise.all(results);
  }


 
  getPanelData(formData: { [key: string]: any }){
    const jsonObj: { [key: string]: any } = {};
    for (const formKey in formData) {
      const varData = formData[formKey];
      jsonObj[varData["key"]] = varData["value"];
    }
    return jsonObj;
  }



  async moveFilesInLargeDocumentToStorage(uploadedFiles:any, studyId:string, patientId:string, tabId:string, panelId:string, fieldId:string){
    // Convert the array to json
    const jsonString = JSON.stringify(uploadedFiles);
    // Create a blob
    const jsonBlob = new Blob([jsonString], { type: 'application/json' });
    // Upload the file to storage
    const storagePath = studyId + '/' + patientId + '/' + GlobalConstant.firestore.key_largeDocumentMovedToStorage + '/'+ 
                        tabId + '/' + panelId + '/' + fieldId + '/' + GlobalConstant.firestore.key_largeDocumentFileName
    const downloadUrl = await this.firestorageService.uploadFileSync(jsonBlob, storagePath);
    return downloadUrl
  }



  
  async anonymizeAndUploadFile(file, storagePath, patientId) {
    try{
      let anonymizeFile = await this.dicomService.anonymizeDicomFile(file, patientId, storagePath);
      let fileName = this.firestoreService.getFirebaseHash() + '.dcm'
      let downloadUrl = await this.firestorageService.uploadFileSync(anonymizeFile, storagePath + '/' + fileName);
      return {'name':fileName, 'downloadUrl':downloadUrl}
    }catch (error){
      // Retornar algo que indique que el archivo falló pero sin bloquear toda la subida
      return null
    }   
  }
  



  evaluateIfItIsASynchronizationPanel(studyId, tabId, panelId){
    const studyTemplate = GlobalConstant.studies[studyId].template
    for(const tabTemplate of studyTemplate){
      if(tabTemplate.key == tabId){
        for(const panelTemplate of tabTemplate.panelList){
          if(panelTemplate.key == panelId){
            if('automaticSynchronization' in panelTemplate) return true
          }
        }
      }
    }
    return false
  }  




  

}

