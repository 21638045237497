import { Component } from '@angular/core';

// services 
import { ControllerService } from '../../../services/controller/controller.service';
import { UserAuthenticationService } from '../../../services/firestore/user-authentication.service';
import { UtilsService } from '../../../services/utils/utils.service';
import { LanguageService } from '../../../services/language/language.service';





/* Specify the structure of the dictionary that will receive the dialog when being openned */


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent {



  constructor(public controllerService:ControllerService,
              public utilsService:UtilsService,
              public userAuthenticationService:UserAuthenticationService,
              public languageService:LanguageService){}




  // ----- Button actions ----- //


  async loginButtonclicked(targetProvider:string){
    // Try to log in and wait for the response
    // Response:
    // {resultOk:bool, error:firebase-error)}
    let resultDict:any = await this.userAuthenticationService.login(targetProvider)
    if(resultDict['resultOk'] == true){}
    else if(resultDict['resultOk'] == false){
      this.utilsService.displaySnackBar(this.languageService.Strings.loginPageStrings.login_error_unauthorized)
    }else{
      this.utilsService.displaySnackBar(this.languageService.Strings.loginPageStrings.login_error_unknown)
    }     

    
  }

  

}
