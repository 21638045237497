
<p mat-dialog-title class='popup-title text-title-size'>
    {{informationTitle}}
</p>

<mat-dialog-content>
    <p class='popup-text noraml-text-size'> {{informationMessage}}</p>
</mat-dialog-content>


<mat-dialog-actions id="actions-conatiner">

    <div class="base_buttons_container"> 
        <!-- Accept button -->        
        <button 
            mat-raised-button 
            color="primary"
            class="base_buttons"
            type="button" 
            (click)="acceptButtonClicked()" > 
            {{languageService.Strings.informationPopup.button_accept}}
        </button>   
    </div>
    
</mat-dialog-actions>