


export const StringsEN = {
    // Specific form 
    form : {
        button_accept: "Accept",
        button_cancel: "Cancel",
        default_tab_name: "New tab",
        default_panel_name: "New panel",
    },
    // Registration and authentication processes
    authentication: {
        register_success: "Verify your email address using the link that we have sent you",
        error_emailNotVerified: "The email has not been verified yet",
        resetPasswordemail_sent: "An email has been sent to ",
        resetPasswordemail_emailNotFound: "User not found",
        resetPasswordemail_unknownError: "Unknown error, try again later or contact us if it does not work",
    },
    // Searching box
    searchingBox: {
        input_box_label_patientId: 'Identifier',
        button_newPatient: 'Add',
        button_findPatient: 'Find',
        button_accessPatient: 'Access',
        button_downloadExcel: 'Download Excel',
        error_spaces: 'Field cannot contain spaces',
        error_specialCharacters: 'Characters not allowed'
    },
    // File selector
    fileSelector: {
        button_selectFiles: 'Select files',
    },
    // Dir selector
    dirSelector: {
        button_selectDirectory: 'Select directory'
    },
    // ---------- Error messages ---------- //
    errorMessages: {
        error_maxValue: "Error! Value too large",
        error_minValue: "Error! Value to small",
        error_step: "Error! Invalid resolution",
        error_required: 'Error! Required field',
        error_maxLength: 'Error! To many characters',
        error_specialCharacters: 'Error! Special characters are not allowed',
        error_spacesInString: 'Error! Spaces are not allowed',
        error_email: 'Error! Value must be a valid email',
        error_downloadExcel: "Excel file could not be downloaded. Please contact support.",
        error_excelNotAvailable: "Excel file is not available. Contact support.",
    },
    // ---------- Popups  ---------- //
    informationPopup: {
        button_accept: "Accept",
    },
    // ---------- Pages ---------- //
    loginPageStrings: {
        login_error_unauthorized: "No authorization to login",
        login_error_unknown: "Login error",
        button_login_google: "Login with Google",
        button_login_microsoft: "Login with Microsoft",
    },
    landingPageStrings: {
        button_back: 'Back',
        waitingMessage_NewPatient: 'Adding new patient',
        informationPopup_userAlreadyExists_title: 'Action failed',
        informationPopup_userAlreadyExists_message: 'Patient already exists',
        informationPopup_error_title: 'Error',
        waitingMessage_FindPatient: 'Searching',
        informationPopup_patientDoesNotExist_title: 'Action failed',
        informationPopup_patientDoesNotExist_message: 'Patient does not exist',
        waitingMessage_UpdatePatient: 'Updating information',
        button_accept: "Accept",
        button_edit: "Edit",
        button_cancel: "Cancel",
        button_download: 'Download',
        button_delete: 'Delete',
        button_logout: 'Logout',
        lastUpdateExcel: "Lastest update of Excel",
        excelStudy: "Study of Excel",
        deletePatientConfirmationTitle: "Delete patient",
        deletePatientConfirmationText: "Once deleted, it cannot be restored",
    },
    // ---------- Specific form items ---------- //
    dicomFieldStrings: {
        information_Patient: 'Patient',
        information_Study: 'Study',
        information_Series: 'Series',
        information_Uploaded: 'Uploaded series',
        waitingMessage_ParsingDicom: 'Loading DICOM',
        waitingMessage_DownloadingDicom: 'Downloading DICOM',
        dicomZipDirName: 'DicomSeries',
        information_UploadedTotal: 'Uploaded',
        information_SelectedTotal: 'Selected',
        information_TotalPatients: 'Patients: ',
        information_TotalSeries: 'Series: ',
        information_TotalImages: 'Images: '
    }
}



