<div class="global-form-div">

    <!-- (submit)="submit()" -->
    <form [formGroup]="formControlGroup_Global">

        <!-- Form fields -->
        <ng-template itemhost></ng-template>

        <button mat-raised-button color="primary" class="base_buttons" (click)="formButtonClicked('submit')" button
            [disabled]="(formCurrentMode === 'edit') || !((formCurrentMode === 'editing') || (formCurrentMode === 'input')) || (!formControlGroup_Global.valid)">
            {{languageService.Strings.landingPageStrings.button_accept}}
        </button>

        <button mat-raised-button color="primary" class="base_buttons" type="button" (click)="formButtonClicked('edit')"
            button [disabled]="(formCurrentMode === 'editing')"
            *ngIf="(formCurrentMode === 'edit') || (formCurrentMode === 'editing')">
            {{languageService.Strings.landingPageStrings.button_edit}}
        </button>

        <button mat-raised-button color="primary" class="base_buttons" type="button"
            (click)="formButtonClicked('cancel')" button
            [disabled]="(formCurrentMode === 'edit') || !(formCurrentMode === 'editing')"
            *ngIf="(formCurrentMode === 'edit') || (formCurrentMode === 'editing')">
            {{languageService.Strings.landingPageStrings.button_cancel}}
        </button>

    </form>

</div>