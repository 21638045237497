<!--
    webkitdirectory -- To select directories instead of files

    webkitdirectory allowdirs msdirectory odirectory directory 
-->

<input 
    id="file-input"
    type="file" 
    multiple
    #fileUpload
    class="file_input"
    (change)="onFileSelected($event)"
    (click)="onFileClick($event)"
    webkitdirectory allowdirs msdirectory odirectory directory
>


<div class="file_upload">
    <button 
        mat-flat-button 
        color="primary"
        class="base_buttons"
        [disabled]="disabled"
        (click)="fileUpload.click()">
        <mat-icon class="icon">folder</mat-icon>
            {{fileUploaderButtonText}}
    </button>
</div>