<div class="input_box">
    
    <mat-form-field appearance="fill" [style.width.%]=100>
  
        <mat-label>{{inputParameters.name}}</mat-label>
 

        <input 
            *ngIf="inputParameters.required" 
            type="number" 
            min={{inputParameters.minValue}} 
            max={{inputParameters.maxValue}} 
            step={{inputParameters.stepValue}} 
            placeholder="" 
            matInput 
            [formControl]="numericFormControl"
            [errorStateMatcher]="matcher" 
            required
            autocomplete="off">

        <input 
            *ngIf="!inputParameters.required" 
            type="number" 
            min={{inputParameters.minValue}} 
            max={{inputParameters.maxValue}} 
            step={{inputParameters.stepValue}} 
            placeholder="" 
            matInput 
            [formControl]="numericFormControl"
            [errorStateMatcher]="matcher" 
            autocomplete="off">

        
        
        <!-- <mat-error *ngIf="(inputParameters.required) && (numericFormControl.hasError('required'))">
        {{errorMessages.error_required}}
        </mat-error> -->

        <mat-error *ngIf="(numericFormControl.hasError('max'))">
        {{languageService.Strings.errorMessages.error_maxValue}}
        </mat-error>
        <mat-error *ngIf="(numericFormControl.hasError('min'))">
        {{languageService.Strings.errorMessages.error_minValue}}
        </mat-error>
        <mat-error *ngIf="(numericFormControl.hasError('stepValidationError'))">
        {{languageService.Strings.errorMessages.error_step}}
        </mat-error>
        
        <!-- <mat-error *ngIf="numericFormControl.hasError('IsANumberError')">
            El valor no es un número o contiene caracteres no permitidos.
        </mat-error> -->
  
      
    </mat-form-field>
    
  </div> 