
<!-- Top bar -->
<mat-toolbar color="primary" class="tool-bar">
  <!-- Left side -->
  <div *ngIf="controllerService.getPatientId()!==null" (click)="button_back()" class="bar-button-left">
    <mat-icon >person</mat-icon>
    <span>{{controllerService.getPatientId()}}</span>
  </div>
  <div *ngIf="controllerService.getPatientId()===null" (click)="button_back()" class="bar-button-left">
    <mat-icon >arrow_back_ios</mat-icon>
    <span style="text-transform: uppercase;">{{languageService.Strings.landingPageStrings.button_back}}</span>
  </div>
  
  <!-- empty space-->
  <div class="empty-space"></div>

  <!-- middle -->
  <span >{{controllerService.getStudyName()}}</span>

  <!-- empty space-->
  <div class="empty-space"></div>

  <!-- Right side -->
  <div (click)="button_logout()" class="bar-button-right">
    <span>{{controllerService.userAuthenticationService.getUserEmail()}}</span>
    <button 
      mat-icon-button (click)="button_logout()"
      matTooltip={{languageService.Strings.landingPageStrings.button_logout}}>
      <mat-icon>logout</mat-icon>
    </button>
  </div>

</mat-toolbar>

<!-- Study template -->
<app-study></app-study>