import { Component, Input, Output,  EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dir-selector',
  templateUrl: './dir-selector.component.html',
  styleUrls: ['./dir-selector.component.css']
})
export class DirSelectorComponent {

  // Output event / parameter that returns the selected files.
  // It needs to be linked in the html of the parent component as (selectedFilesEvent)="parentMethod($event)"
  @Output() selectedFilesEvent = new EventEmitter<any>();

  // Input parameter that indicates the text to be shown in the button
  // It needs to be specified in the html of the parent component as [fileUploaderButtonText]="desiredValue"
  @Input() fileUploaderButtonText:any;

  // Input parameter that indicates if the button is enabled or disabled
  // It needs to be specified in the html of the parent component as [disabled]="desiredValue"
  @Input() disabled:boolean;


  
  constructor() { }

  ngOnInit(): void {
    // For now, empty
  }

  onFileSelected(event:any){
    let files = event.target.files
    this.selectedFilesEvent.emit(files)
  }

  onFileClick(event:any){
    // Clear the value so it detects a change if the same file is selected again
    event.target.value = ''
  }

}
