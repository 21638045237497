<div class="input_box">

    <mat-label 
        class="tag-label-div"
        [ngClass]="{ 'disabled-label': fieldDisabled }">
        {{inputParameters.name}}
    </mat-label>

    <!-- Selected files -->
    <mat-list class=global-list-div *ngIf="(filesList.length > 0) || (selectedFilesList.length > 0) ">

        <!-- Existing files lists -->
        <div class=single-item-div *ngFor="let itemDict of filesList; let itemIndex=index">
            <div class=single-item-div-internal>
                <!-- File information and actions-->
                <div class="single-item-div-left"
                    [ngClass]="{ 'disabled-label': fieldDisabled }">
                    {{itemDict['name']}}
                </div>   
                <div class="single-item-div-right">
                    <button 
                        mat-icon-button 
                        color="primary"
                        (click)="downloadFile(itemIndex, 'cloud')"
                        style="text-transform: uppercase"
                        matTooltip={{languageService.Strings.landingPageStrings.button_download}}>
                        <mat-icon>cloud_download</mat-icon>
                    </button>
                    <button 
                        mat-icon-button 
                        color="primary"
                        (click)="deleteFile(itemIndex, 'cloud')" 
                        [disabled]="fieldDisabled"
                        matTooltip={{languageService.Strings.landingPageStrings.button_delete}}>
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>  
            </div>
            <!-- Separator line -->
            <mat-divider *ngIf="itemIndex < filesList.length - 1"></mat-divider>
        </div>

        <!-- Separator line -->
        <mat-divider *ngIf="(filesList.length > 0) && (selectedFilesList.length > 0)"></mat-divider>

        <!-- New files lists -->
        <div class=single-item-div *ngFor="let itemDict of selectedFilesList; let itemIndex=index">
            <div class=single-item-div-internal>
                <!-- File information and actions-->
                <div class="single-item-div-left"
                    [ngClass]="{ 'disabled-label': fieldDisabled }">
                    {{itemDict['name']}}
                </div>   
                <div class="single-item-div-right">
                    <button 
                        mat-icon-button 
                        color="primary"
                        (click)="downloadFile(itemIndex, 'local')"
                        style="text-transform: uppercase"
                        matTooltip={{languageService.Strings.landingPageStrings.button_download}}>
                        <mat-icon>cloud_download</mat-icon>
                    </button>
                    <button 
                        mat-icon-button 
                        color="primary"
                        (click)="deleteFile(itemIndex, 'local')" 
                        [disabled]="fieldDisabled"
                        matTooltip={{languageService.Strings.landingPageStrings.button_delete}}>
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>  
            </div>
            <!-- Separator line -->
            <mat-divider *ngIf="itemIndex < selectedFilesList.length - 1"></mat-divider>
        </div>
    </mat-list>



    <app-file-selector 
        (selectedFilesEvent)="onFileSelected($event)"
        [fileUploaderButtonText]="currentFileSelectorString"
        [fileUploaderFileType]="currentFileSelectorType"
        [disabled]="fieldDisabled">
    </app-file-selector>

</div> 
