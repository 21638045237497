<div class="input_box">

    <mat-label class="tag-label-div"
        [ngClass]="{ 'disabled-label': fieldDisabled }">
        {{inputParameters.name}}
    </mat-label>

    <!-- Single select -->
    <div [formGroup]="formControlGroup" *ngIf="!inputParameters.multiselect">
        <mat-radio-group color="primary" class="pannel-buttons-container" formControlName="value">
            <mat-radio-button #currentButton class="single-button-container"
                *ngFor="let categoryName of inputParameters.categories ; let i = index" [value]=i
                class="single-button-container" [checked]=categorySelection[i]
                (click)="radioButtonClicked($event, currentButton)" [disabled]="fieldDisabled" [style.pointer-events]="fieldDisabled ? 'none' : 'auto'">
                {{categoryName}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <!-- 
        formControlName:    the name of the specific formControl inside the formGroup (the one with the output result)
        [value]:            current value that will be recieved and stored in formControlName
        (change)="handleChange($event)":    this can go inside <mat-radio-button> to detect changes
        -->


    <!-- Multi select -->
    <div [formGroup]="formControlGroup" *ngIf="inputParameters.multiselect" class="pannel-buttons-container">
        <div *ngFor="let categoryName of inputParameters.categories ; let i = index"
            class="single-button-container-div">
            <mat-checkbox color="primary" class="single-button-container" (change)="handleChangeCheckBox($event, i)"
                [value]=inputParameters.categories[i] [checked]="categorySelection[i]" [disabled]="fieldDisabled" [style.pointer-events]="fieldDisabled ? 'none' : 'auto'">
                {{categoryName}}
            </mat-checkbox>
        </div>
    </div>

</div>


<!-- [readonly]="readOnly" (for input texts) -->
<!-- disabled="readOnly" for checkboxs and radio buttons -->