import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, NgModel, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { LanguageService } from 'src/app/services/language/language.service'

/* Error when invalid control is dirty, touched, or submitted (input fields) */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    //const isSubmitted = form && form.submitted;
    //return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    return !!(control && control.invalid && (control.dirty || control.touched ));
  }
}




@Component({
  selector: 'app-numeric-field',
  templateUrl: './numeric-field.component.html',
  styleUrls: ['./numeric-field.component.css']
})
export class NumericFieldComponent implements OnInit {


  // Input parameters. Pass through the reference when creating the view
  public inputParameters:any = {}
  public inputData:any = {}


  // Maximum number of decimal places
  maximumNumberOfDecimalPlaces:number = 0



  // Form data dictionray
  public formData: any;
  public formControlGroup:any;
  
  // Individual form controls and error matcher
  numericFormControl = new FormControl(null, []); 
  matcher = new MyErrorStateMatcher();





  constructor(public languageService: LanguageService) { }

  ngOnInit(): void {
    
    // Form group for Login process
    this.formControlGroup = new FormGroup({
      value: this.numericFormControl
    })

    // Add the validators
    //    Required validator
    if(this.inputParameters.required){
      this.numericFormControl.addValidators(Validators.required)
    }
    //    Max value validator
    if(this.inputParameters.maxValue){
      this.numericFormControl.addValidators(Validators.max(this.inputParameters.maxValue))
    }
    //    Min value validator
    if(this.inputParameters.minValue){
      this.numericFormControl.addValidators(Validators.min(this.inputParameters.minValue))
    }
    //    Step validator
    if(this.inputParameters.stepValue){
      this.maximumNumberOfDecimalPlaces = this.getNumberOfDecimals(this.inputParameters.stepValue)
      this.numericFormControl.addValidators(this.stepsValidator.bind(this))
    }
    // //    Is a number validator
    // this.numericFormControl.addValidators(this.IsANumberValidator.bind(this))

    // Set the current value   
    if(this.inputData){
      if(this.inputData.value !== undefined){
        this.numericFormControl.setValue(this.inputData.value)
      }
    }
  }





  // ----- Custom validators ------ //

  // Custom step validation functions
  stepsValidator(control: AbstractControl) {
    if (this.inputParameters.stepValue){
      // Verify that the number of decimal places of the introduced number is lower than the maximum according to the step
      if(this.getNumberOfDecimals(control.value)>this.maximumNumberOfDecimalPlaces){
        return { stepValidationError: true };
      } 
      // Verify that the introduced number is divisible by the step
      else if (this.divisionModule(control.value, this.inputParameters.stepValue) !== 0) {
        return { stepValidationError: true };
      }
    }
    return null;
  }
  getNumberOfDecimals(targetNumber:number){
    if(targetNumber){
      if(Math.floor(targetNumber) === targetNumber) return 0;
      return targetNumber.toString().split(".")[1].length; 
    }
   return 0
  }
  divisionModule(numerator:number, divider:number){
    if(divider>=1){
      return numerator%divider
    }else{
      numerator = numerator/divider
      // Round to a higher number of decimals than the step decimals to prevent aproximation errors such as 0.3/0.1=2.999999999999996
      numerator = Math.round(numerator * this.maximumNumberOfDecimalPlaces * 10000)  / (this.maximumNumberOfDecimalPlaces * 10000) 
      return numerator%1
    }
  }
  // IsANumberValidator(control: AbstractControl) {
  //   let addmittedCharacters = /^[0-9.]*$/;
  //   let currentString = control.value
  //   if(currentString){
  //     if(currentString.length > 0){
  //       if(!addmittedCharacters.test(currentString)){
  //         return { IsANumberError: true };
        
  //       }
  //     }
  //   }

  //   console.log(currentString)
  //   return null;
  // }



  // ----- Functions to return the corresponding information to parent class ------ //

  getResultDict(){
    let resultDict = {
      key: this.inputParameters.key,
      type: this.inputParameters.type,
      value:this.formControlGroup.value.value
    }
    return resultDict
  }





}
