import { Component, Input, OnInit } from '@angular/core';



@Component({
  selector: 'app-waiting-spinner',
  templateUrl: './waiting-spinner.component.html',
  styleUrls: ['./waiting-spinner.component.css']
})
export class WaitingSpinnerComponent implements OnInit {

  // Input parameters. Pass from the caller class binding the input through the HTML
  @Input() waitingMessage:string = '';

  constructor() { }

  ngOnInit(): void {
    // For now, empty
  }

}
